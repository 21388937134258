import React, { useState, useEffect, useRef } from "react";
import OfferService from "../../services/OfferService";
import LinkName from "../LinkName/LinkName";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";
import { useFormik } from "formik";
moment.tz.setDefault(process.env.REACT_APP_MOMENT_TIME_ZONE);
const ValidateSchema = Yup.object({
  product_name: Yup.string().required("Product is required"),
  quantity: Yup.string().required("Quantity is required"),
});
function InventoryHistory() {
  const onSubmit = async (values) => {
    const obj = { ...values };
    obj.upc = inventoryList[0].upc;
    obj.old_quantity = inventoryList[0].quantity;

    let formObj = new FormData();
    Object.keys(obj).forEach((k) => {
      if (k == "images") {
        for (let x = 0; x < obj.images.length; x++) {
          if (typeof obj.images[x] != "string") {
            formObj.append("images", obj.images[x]);
          } else {
            formObj.append("images", obj.images[x]);
          }
        }
      } else {
        formObj.append(k, obj[k]);
      }
    });

    const result = await offerServ.addInventoryHistory(formObj);
    if (result.err) {
      toast.error(result.message);
    } else {
      toast.success(result.message);
      setTimeout(() => {
        navigate("/inventory/list");
      }, 1000);
    }
  };
  const [product, setProduct] = useState({
    product_name: "",
    quantity: "",
    images: [],
    remarks: "",
    brand: "",
    upc: "",
    model_num: "",
  });

  useEffect(() => {}, []);
  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };
  const formik = useFormik({
    initialValues: product,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  const [inventoryList, setInventoryList] = useState([]);
  const [showRemarks, setShowRemarks] = useState({ open: false, remarks: "" });
  const offerServ = new OfferService();
  const navigate = useNavigate();
  const [contentLoaded, setContentLoaded] = useState(false);
  const { upc } = useParams();
  const InventoryList = async (upc) => {
    const result = await offerServ.getInventoryHistory({ upc });
    if (result.data) {
      setContentLoaded(true);
      for (let i = 0; i < result.data.length; i++) {
        result.data[i].createdAt = moment(result.data[i].createdAt).format("MM-DD-YYYY");
      }
    }
    const inv = await offerServ.getInventory(upc);
    if (inv.result.images) {
      inv.result.images = inv.result.images.split(",");
    } else {
      inv.result.images = [];
    }
    setProduct(inv.result);
    setInventoryList(result.data);
  };
  useEffect(() => {
    if (upc) {
      InventoryList(upc);
    }
  }, []);

  const handleRemarks = (data) => {
    let remarks = "";
    remarks = data.remarks;
    setShowRemarks({
      open: true,
      remarks,
    });
  };
  return (
    <>
      {!contentLoaded ? (
        <div className="loadingImg">
          <img src="/assets/images/loading.gif" className="text-center" />
        </div>
      ) : (
        <div className="ljSectionData w-100 clearfix ljpUserTables pb-4 p-relative" id="ljpUserTables">
          <LinkName heading="Inventory Database" image="/assets/images/icons/user.png" link="/user_table" />
          <div className="user_part mb-3">
            <div className="users_bottom_part">
              <form onSubmit={formik.handleSubmit}>
                <div className="m-3">
                  <div className="row  mb-4">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Product Name*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Product name"
                          value={formik.values.product_name}
                          name="product_name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.product_name && formik.errors.product_name
                          ? dispErrorMsg("Product name is required")
                          : null}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Quantity*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Quantity"
                          value={formik.values.quantity}
                          name="quantity"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.quantity && formik.errors.quantity
                          ? dispErrorMsg("Quantity is required")
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="row  mb-4">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">UPC*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="UPC"
                          value={formik.values.upc}
                          name="upc"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          readOnly
                        />
                        {formik.touched.upc && formik.errors.upc ? dispErrorMsg("UPC is required") : null}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Brand</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Brand"
                          value={formik.values.brand}
                          name="brand"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* <textarea
                        class="form-control"
                        placeholder="Brand"
                        id="textAreaExample1"
                        name="Brand"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.brand}
                        rows="3"
                      ></textarea> */}
                        {formik.touched.brand && formik.errors.brand ? dispErrorMsg("Brand is required") : null}
                      </div>
                    </div>
                  </div>
                  <div className="row  mb-4">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Model</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Model"
                          value={formik.values.model_num}
                          name="model_num"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* <textarea class="form-control" id="textAreaExample1" rows="3"
                       placeholder="Model"
                       value={formik.values.model_num}
                       name="Model"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}></textarea> */}
                        {formik.touched.model_num && formik.errors.model_num ? dispErrorMsg("Model is required") : null}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Remarks</label>
                        {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Remarks"
                        value={formik.values.remarks}
                        name="Remarks"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      /> */}
                        <textarea
                          class="form-control"
                          id="textAreaExample1"
                          rows="3"
                          placeholder="Remarks"
                          value={formik.values.remarks}
                          name="remarks"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        ></textarea>
                        {formik.touched.remarks && formik.errors.remarks ? dispErrorMsg("Remarks is required") : null}
                      </div>
                    </div>
                  </div>
                  <div className="row  mb-4">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label">Images</label>
                        {formik.values.images && formik.values.images.length > 0 ? (
                          Object.values(formik.values.images).map((im, index) => {
                            if (typeof im != "string") {
                              return (
                                <>
                                  <img
                                    src={URL.createObjectURL(im)}
                                    className="imgPreview"
                                    onClick={(e) => {
                                      window.open(e.target.src, "_blank");
                                    }}
                                  />
                                  <i
                                    onClick={(e) => {
                                      let text = "Want to remove image";
                                      if (confirm(text) == true) {
                                        formik.values.images = formik.values.images.filter(
                                          (obj) => obj !== formik.values.images[index]
                                        );
                                        let array = [...formik.values.images];
                                        formik.setFieldValue("images", array);
                                      } else {
                                      }
                                    }}
                                    className="fa fa-sharp fa-solid fa-trash img_dl_icon"
                                  ></i>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <img
                                    src={im}
                                    className="imgPreview "
                                    onClick={(e) => {
                                      window.open(e.target.src, "_blank");
                                    }}
                                  />
                                  <i
                                    onClick={(e) => {
                                      let text = "Want to remove image";
                                      if (confirm(text) == true) {
                                        formik.values.images = formik.values.images.filter(
                                          (obj) => obj !== formik.values.images[index]
                                        );
                                        let array = [...formik.values.images];
                                        formik.setFieldValue("images", array);
                                      } else {
                                      }
                                    }}
                                    className="fa fa-sharp fa-solid fa-trash img_dl_icon"
                                  ></i>
                                </>
                              );
                            }
                          })
                        ) : (
                          <></>
                        )}
                        <input
                          type="file"
                          name="images"
                          multiple
                          className={
                            "form-control" + (formik.touched.images && formik.errors.images ? " is-invalid" : "")
                          }
                          onChange={(e) => {
                            let array1 = formik.values.images;
                            let array2 = e.target.files;
                            let mergedArray = [...array1, ...array2];
                            formik.setFieldValue("images", mergedArray);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row  mb-4">
                    <div className="col-6">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="user_part">
            <div className="users_bottom_part">
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap user_bottom_header align-items-center margintxt ">
                  <div className="text-heading d-flex justify-content-between">
                    <h4 className="mb-0">Inventory History</h4>
                  </div>
                </div>
                <div className="m-3">
                  <button
                    className="btn btn-secondary remove-items-btn col-1 payment-btn-height ms-4 mt-0"
                    onClick={() => navigate("/inventory/list")}
                  >
                    Back
                  </button>
                </div>
              </div>
              <div className="para">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
              <div className="users_table mt-3">
                <div className="current_courses_table table-responsive">
                  <table className="main_table w-100">
                    <thead>
                      <th>Offer Id</th>
                      <th>UPC</th>
                      <th>Remarks</th>
                      <th>Old Quantity</th>
                      <th>New Quantity</th>
                      <th>createdAt</th>
                    </thead>

                    <tbody>
                      {Array.isArray(inventoryList) &&
                        inventoryList?.map((data, i) => {
                          return (
                            <>
                              <tr>
                                <td>
                                  {data.offer_id ? (
                                    <Link to={`/offer/preview/${data.offer_id}`}>{data.offer_id}</Link>
                                  ) : (
                                    "NF"
                                  )}
                                </td>
                                <td>{data.upc}</td>
                                <td className="text-center hands-icon">
                                  {data.remarks && (
                                    <i
                                      className="fa fa-sticky-note-o"
                                      aria-hidden="true"
                                      onClick={() => {
                                        handleRemarks(data);
                                      }}
                                    ></i>
                                  )}
                                </td>
                                <td>{data.old_quantity}</td>
                                <td>{data.new_quantity}</td>
                                <td>{data.createdAt}</td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showRemarks.open == true && (
        <div className="modal" style={{ display: "block" }}>
          <div className="user-modal-container add-edit-user-modal">
            <div className="d-flex justify-content-between">
              <h3 className="text-center">Remarks</h3>

              <button
                type="button"
                className="btn btn-secondary remove-items-btn mt-0"
                onClick={() =>
                  setShowRemarks({
                    open: false,
                    remarks: "",
                  })
                }
              >
                X
              </button>
            </div>
            <div></div>
            <div className="mt-2 row">
              <div className="col-12" style={{ whiteSpace: "pre-wrap" }}>
                {showRemarks.remarks}
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
}
export default InventoryHistory;
