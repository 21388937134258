import React, { useEffect, useState } from "react";
import SellerPage from "./SellerInfo.js";
import { Await, Link } from "react-router-dom";
import PieChartService from "../../services/PieChartService";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import UserService from "../../services/UserService";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";

const pieServ = new PieChartService();
const userServ = new UserService();
function HomePage() {
  const [chartData, setChartData] = useState({});
  const [filterDay, setFilterDay] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const getPieChartData = async () => {
    const series = await pieServ.getData();
    console.log("series is ", series);
    const options = {
      chart: {
        type: "pie",
      },
      title: {
        text: "UPC Chart",
      },
      plotOptions: {
        series: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
      },
    };
    options.series = series;
    console.log("series is ", options);
    setChartData((prev) => options);
  };
  useEffect(() => {
    getPieChartData();
  }, []);
  useEffect(() => {
    getCardData();
  }, [filterDay, startDate, endDate]);
  const [cardResponse, setCardResponse] = useState("");
  const getCardData = async () => {
    try {
      if (filterDay == "custom") {
        let response = await userServ.getCountDataDateRange(
          UserId,
          startDate ? moment(startDate).tz("America/New_York").format("YYYY-MM-DD") : "2000-01-01",
          endDate ? moment(endDate).tz("America/New_York").format("YYYY-MM-DD") : "2060-12-31"
        );
        setCardResponse(response);
      } else if (filterDay == "this_year") {
        let Difference_In_Days = moment().dayOfYear();
        let response = await userServ.getCountData(Difference_In_Days);
        setCardResponse(response);
      } else {
        let response = await userServ.getCountData(filterDay);
        setCardResponse(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <div className="d-flex justify-content-between dashboardHeading row ">
        <div className="row">
          <div className="title-container-dashbrd mb-4 col-sm-4 col-md-4">
            <img
              src="/assets/images/icons/Vector(25).png"
              alt="logo"
              className="img-fluid sidebar-img "
              style={{ color: "#4A5056" }}
              width="22"
            />
            <span
              style={{
                paddingLeft: "10px",
                marginTop: "-7px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "28px",
                color: "#4A5056",
              }}
            >
              Admin Stands
            </span>
          </div>
          <div className="col-sm-4 col-md-4"></div>
          <div className="col-sm-4 col-md-4 d-flex justify-content-between filterInp mb-4">
            {filterDay != "custom" ? (
              <>
                <select
                  className="form-control selectInp"
                  onChange={(e) => {
                    setFilterDay(e.target.value);
                  }}
                >
                  <option value="1">Today </option>
                  <option value="7">7 Days</option>
                  <option value="30">30 Days</option>
                  <option value="60">60 Days</option>
                  <option value="90">90 Days</option>
                  <option value="this_year">This Year</option>
                  <option value="custom">Custom</option>
                </select>
                <i class="width10 fas fa-filter m-auto mx-2"></i>
              </>
            ) : (
              filterDay == "custom" && (
                <div className="row">
                  <div className="col-md-5 mt-1 mb-1">
                    <DatePicker
                      placeholderText="Start Date"
                      onChange={(date) => setStartDate(date)}
                      selected={startDate}
                      maxDate={endDate}
                    />
                  </div>
                  <div className="col-md-5 mt-1 mb-1">
                    <DatePicker
                      placeholderText="End Date"
                      onChange={(date) => setEndDate(date)}
                      selected={endDate}
                      minDate={startDate}
                    />
                  </div>
                  <div className="col-md-1 mt-2">
                    <i class="width10 fa fa-close m-auto mx-2" onClick={() => setFilterDay(1)}></i>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      <div className="allCardList">
        <div className="row">
          <div className=" card-box-dashbrd col-xl-2 col-md-6 col-6 pb-6">
            <div className="FullViews card">
              <Link to={`/user?s=&d=${filterDay}&sd=${startDate}&ed=${endDate}`} className="d-flex">
                <h6 className="m-0">Total Users</h6>
                <h3 className="m-0 full-amount">{cardResponse?.total_users_count?.cnt}</h3>
              </Link>
            </div>
          </div>
          <div className="card-box-dashbrd col-xl-2 col-md-6 col-6 pb-4">
            <div className="FullViews card">
              <Link to={`/user?s=active&d=${filterDay}&sd=${startDate}&ed=${endDate}`} className="d-flex">
                <h6 className="m-0">Active Users</h6>
                <h3 className="full-amount m-0">{cardResponse?.active_users_count?.cnt}</h3>
              </Link>
            </div>
          </div>
          <div className="card-box-dashbrd col-xl-2 col-md-6 col-6 pb-4">
            <div className="FullViews card">
              <Link to={`/user?s=inactive&d=${filterDay}&sd=${startDate}&ed=${endDate}`} className="d-flex">
                <h6 className="m-0">Inactive Users</h6>
                <h3 className="m-0 full-amount">{cardResponse?.inactive_users_count?.cnt}</h3>
              </Link>
            </div>
          </div>
          <div className="col-xl-2 col-md-6 col-6 pb-4 card-box-dashbrd">
            <div className="FullViews card">
              <Link
                to={`/offerlist?s=paid||in-progress&d=${filterDay}&sd=${startDate}&ed=${endDate}`}
                className="d-flex"
              >
                <h6 className="m-0 ">Paid In Progress Offer & Amount</h6>
                <h3 className="m-0 full-amount">
                  {cardResponse?.paid_inprogress_offers_count_and_amount?.cnt}- $
                  {cardResponse?.paid_inprogress_offers_count_and_amount?.amount
                    ? cardResponse?.paid_inprogress_offers_count_and_amount?.amount
                    : 0}
                </h3>
              </Link>
            </div>
          </div>
          <div className="col-xl-2 col-md-6 col-6 pb-4 card-box-dashbrd">
            <div className="FullViews card">
              <Link to={`/offerlist?s=approved&d=${filterDay}&sd=${startDate}&ed=${endDate}`} className="d-flex">
                <h6 className="m-0 ">Approved Offer & Amount</h6>
                <h3 className="m-0 full-amount">
                  {cardResponse?.total_approved_offers_count_and_amount?.cnt
                    ? cardResponse?.total_approved_offers_count_and_amount?.cnt
                    : 0}{" "}
                  - $
                  {cardResponse?.total_approved_offers_count_and_amount?.amount
                    ? cardResponse?.total_approved_offers_count_and_amount?.amount
                    : 0}
                </h3>
              </Link>
            </div>
          </div>
          <div className="col-xl-2 col-md-6 col-6 pb-4 card-box-dashbrd">
            <div className="FullViews card">
              <Link to={`/offerlist?s=closed&d=${filterDay}&sd=${startDate}&ed=${endDate}`} className="d-flex">
                <h6 className="m-0 ">Total Payouts Count & Amount</h6>
                <h3 className="m-0 full-amount">
                  {cardResponse?.total_payouts_count_and_amount?.cnt} - $
                  {cardResponse?.total_payouts_count_and_amount?.amount
                    ? cardResponse?.total_payouts_count_and_amount?.amount
                    : 0}
                </h3>
              </Link>
            </div>
          </div>
          <div className="col-xl-2 col-md-6 col-6 pb-4 card-box-dashbrd">
            <div className="FullViews card">
              <Link to={`/offerlist?s=unpaid||closed&d=${filterDay}&sd=${startDate}&ed=${endDate}`} className="d-flex">
                <h6 className="m-0 ">Total Pending Payouts & Amount</h6>
                <h3 className="m-0 full-amount">
                  {cardResponse?.total_pending_payouts_count_and_amount?.cnt} - $
                  {cardResponse?.total_pending_payouts_count_and_amount?.amount
                    ? cardResponse?.total_pending_payouts_count_and_amount?.amount
                    : 0}
                </h3>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <SellerPage />
      <HighchartsReact highcharts={Highcharts} options={chartData ? chartData : {}} />
    </div>
  );
}

export default HomePage;
