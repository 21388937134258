import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import BannerService from "../../services/BannerService";
import { toast, ToastContainer } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";

const ValidateSchema = Yup.object({
  title: Yup.string(),
  subtitle: Yup.string(),
  primary_image: Yup.string().required("Primary Image is required"),
});
let BannerID;
function Banner() {
  const { id } = useParams();
  BannerID = id;
  const navigate = useNavigate();
  const bannerServ = new BannerService();
  const [banner, setBanner] = useState({
    title: "",
    subtitle: "",
    // background_image: [],
    primary_image: [],
  });

  useEffect(() => {
    if (id) {
      fetchBannerDetails(id);
    }
    // else {
    //   setContentLoaded(true);
    // }
  }, []);

  async function fetchBannerDetails(Id) {
    const details = await bannerServ.getid(Id);
    setBanner((prev) => details.data);
    //setContentLoaded(true);
  }
  const onSubmit = async (values) => {
    // e.preventDefault();
    const obj = { ...values };
    const formObj = new FormData();
    Object.keys(obj).forEach((k) => {
      if (k == "primary_image") {
        console.log("tyoe if", typeof obj[k]);
        if (typeof obj[k] == "object") {
          for (let x = 0; x < obj.primary_image.length; x++) {
            if (typeof obj.primary_image[x] != "string") {
              formObj.append("primary_image", obj.primary_image[x]);
            } else {
              formObj.append("primary_image", obj.primary_image[x]);
            }
          }
        } else {
          formObj.append("primary_image", obj[k]);
        }
      }
      // else if (k == "background_image") {
      //   if (typeof obj[k] == "object") {
      //     for (let x = 0; x < obj.background_image.length; x++) {
      //       if (typeof obj.background_image[x] != "string") {
      //         formObj.append("background_image", obj.background_image[x]);
      //       } else {
      //         formObj.append("background_image", obj.background_image[x]);
      //       }
      //     }
      //   } else {
      //     formObj.append("background_image", obj[k]);
      //   }
      // }
      else {
        formObj.append(k, obj[k]);
      }
    });
    for (const pair of formObj.entries()) {
      console.log(`${pair[0]}, ${pair[1]}`);
    }
    const result = await bannerServ.BannerInfo(formObj);
    if (result.error) {
      toast.error(result.message);
    } else {
      toast.success(result.message);
      setTimeout(() => {
        navigate("/bannerList");
      }, 1000);
    }
  };
  const formik = useFormik({
    initialValues: banner,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };

  const downloadHandler = async (filePath) => {
    try {
      window.open(filePath, "_blank");
    } catch (err) {
      return false;
    }
  };

  return (
    <div>
      <section>
        <ToastContainer />
      </section>
      <div className="m-4">
        <div className="user_part">
          <div className="users_bottom_part">
            <div className="text-heading my-2 mx-4 row">
              <h4 className=" text-center m-auto col-11">Banner Details</h4>
              <button
                className="btn btn-secondary remove-items-btn col-1 payment-btn-height mt-0"
                onClick={() => navigate("/bannerList")}
              >
                Back
              </button>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="m-3">
                <div className="row  mb-4">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="form-label">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Title"
                        value={formik.values.title}
                        name="title"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.title && formik.errors.title ? dispErrorMsg("Title is required") : null}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label className="form-label">SubTitle</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="SubTitle"
                        value={formik.values.subtitle}
                        name="subtitle"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.subtitle && formik.errors.subtitle ? dispErrorMsg("Subtitle is required") : null}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label className="form-label">Primary image*</label>

                      {formik.values.primary_image &&
                      typeof formik.values.primary_image != "string" &&
                      formik.values.primary_image.length > 0 ? (
                        Object.values(formik.values.primary_image).map((im, index) => {
                          if (typeof im != "string") {
                            return (
                              <>
                                <img
                                  src={URL.createObjectURL(im)}
                                  className="imgPreview"
                                  onClick={(e) => {
                                    downloadHandler(e.target.src);
                                  }}
                                />
                                <i
                                  onClick={(e) => {
                                    let text = "want to remove image";
                                    if (confirm(text) == true) {
                                      let array = [];
                                      formik.setFieldValue("primary_image", array);
                                    } else {
                                    }
                                  }}
                                  className="fa fa-sharp fa-solid fa-trash img_dl_icon"
                                ></i>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <img
                                  src={im}
                                  className="imgPreview "
                                  onClick={(e) => {
                                    downloadHandler(e.target.src);
                                  }}
                                />
                                <i
                                  onClick={(e) => {
                                    let text = "want to remove image";
                                    if (confirm(text) == true) {
                                      let array = [];
                                      formik.setFieldValue("primary_image", array);
                                    } else {
                                    }
                                  }}
                                  className="fa fa-sharp fa-solid fa-trash img_dl_icon"
                                ></i>
                              </>
                            );
                          }
                        })
                      ) : formik.values.primary_image.length > 0 ? (
                        <>
                          <img
                            src={formik.values.primary_image}
                            className="imgPreview"
                            onClick={(e) => {
                              downloadHandler(e.target.src);
                            }}
                          />
                          <i
                            onClick={(e) => {
                              let text = "want to remove image";
                              if (confirm(text) == true) {
                                let array = [];
                                formik.setFieldValue("primary_image", array);
                              } else {
                              }
                            }}
                            className="fa fa-sharp fa-solid fa-trash img_dl_icon"
                          ></i>
                        </>
                      ) : (
                        <></>
                      )}
                      <input
                        type="file"
                        className="form-control"
                        placeholder="SubTitle"
                        //   value={formik.values.primary_image}
                        name="primary_image"
                        onChange={(e) => {
                          formik.setFieldValue("primary_image", e.target.files);
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.primary_image && formik.errors.primary_image
                        ? dispErrorMsg("Primary image is required")
                        : null}
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <div className="form-group">
                      <label className="form-label">Background Image</label>
                      <input
                        type="file"
                        className="form-control"
                        placeholder="SubTitle"
                        //   value={formik.values.background_image}
                        name="background_image"
                        onChange={(e) => {
                          formik.setFieldValue("background_image", e.target.files);
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.values.background_image && formik.values.background_image > 0 ? (
                        Object.values(formik.values.background_image).map((im, index) => {
                          if (typeof im != "string") {
                            return (
                              <>
                                <img
                                  src={formik.values.background_image}
                                  className="imgPreview"
                                  //   onClick={(e) => {
                                  //     let text = "want to remove image"
                                  //     if (confirm(text) == true) {
                                  //       formik.values.images_after_delivery = formik.values.images_after_delivery.filter((obj) => obj !== formik.values.images_after_delivery[index]) // create a new array without the object with id = 2
                                  //       let array = [...formik.values.images_after_delivery];
                                  //       formik.setFieldValue("images_after_delivery", array);
                                  //     } else {
                                  //     }
                                  //   }}
                                  onClick={(e) => {
                                    window.open(e.target.src, "_blank");
                                  }}
                                />
                                {formik.values.background_image}
                              </>
                            );
                          } else {
                            return (
                              <>
                                <img
                                  src={formik.values.background_image}
                                  className="imgPreview"
                                  onClick={(e) => {
                                    window.open(e.target.src, "_blank");
                                  }}
                                />
                                {formik.values.background_image}
                              </>
                            );
                          }
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div> */}
                </div>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Banner;
