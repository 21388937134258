import util from "../util/util";
export default class BannerService {

    async BannerInfo(payload){
        
        return util.sendApiRequestWithFiles("/banner/info","POST",true,payload).then(
            (response) =>{
                return response
            },
            (error) =>{
                throw error
            }
        )
    }

    async info(){
        return util
        .sendApiRequest("/banner/list", "GET", true, {})
        .then(
          (response) => {
            return response;
          },
          (error) => {
            throw error;
          }
        );
    }

    async delete(id){
        return util
        .sendApiRequest(`/banner/delete/${id}`, "DELETE", true)
        .then(
          (response) => {
            return response;
          },
          (error) => {
            throw error;
          }
        );
    }

    async getid(id){
        return util
        .sendApiRequest(`/banner/get/${id}`, "GET", true)
        .then(
          (response) => {
            return response;
          },
          (error) => {
            throw error;
          }
        );
    }
}