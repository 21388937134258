import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import OfferService from "../../services/OfferService";
import UserService from "../../services/UserService";
import { ToastContainer } from "react-toastify";
import download from "downloadjs";
import moment from "moment-timezone";
import MessageService from "../../services/MessageService";
// import PageBanner from "../PageBanner";
// import SideBar from "../seller/SideBar";
moment.tz.setDefault(process.env.REACT_APP_MOMENT_TIME_ZONE);
const service = new OfferService();
const userServ = new UserService();
const messageServ = new MessageService();
let offerId;
function PreviewOffers() {
  const { id } = useParams();
  const navigate = useNavigate();
  offerId = id;
  const [offer, setOfferDetails] = useState({});
  const [offerProducts, setOfferProducts] = useState([]);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [historyLength, setHistoryLength] = useState({
    activityLength: 0,
    messageLength: 0,
  });
  const [offerHistory, setOfferHistory] = useState([]);
  const [messageList, setMessageList] = useState([]);
  useEffect(() => {
    const interval = setInterval(myFunction, 5000);
    return () => clearInterval(interval);
  }, []);

  const myFunction = async () => {
    const result = await messageServ.list({
      id,
      sortBy: { send_date: "desc", send_time: "desc" },
    });
    setMessageList((prev) => result);
  };
  const [shippingDetails, setShippingDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [showMsgHis, setShowMsgHis] = useState(false);
  const [showActHis, setShowActHis] = useState(false);
  useEffect(async () => {
    await fetchOfferList(offerId);
  }, []);
  const fetchOfferList = async (id) => {
    try {
      const response = await service.getOfferInfo(id);
      const shipping = await service.getShipping();
      setShippingDetails((prev) => shipping.data[0]);
      const offfeerHistory = await service.offerHistory({
        id,
        sortBy: { createdAt: "desc" },
      });
      const result = await messageServ.list({
        id,
        sortBy: { send_date: "desc", send_time: "desc" },
      });
      const userId = response?.offer?.user_id;
      if (userId) {
        const userData = await userServ.getUser(userId);
        setUserDetails((prev) => userData);
      }
      if (response && offfeerHistory) {
        setContentLoaded(true);
        if (response.offer.images) {
          response.offer.images = response.offer.images.split(",");
        } else {
          response.offer.images = [];
        }
        if (response.offer.images_after_delivery) {
          response.offer.images_after_delivery = response.offer.images_after_delivery.split(",");
        } else {
          response.offer.images_after_delivery = [];
        }
        for (let i = 0; i < offfeerHistory.data.length; i++) {
          offfeerHistory.data[i].createdAt = moment(offfeerHistory.data[i].createdAt).format("MM-DD-YYYY hh:mm:ss");
        }
        if (response.offer.payment_details?.platform) {
          const platform = response.offer.payment_details.platform;
          let index = platform.indexOf("-");
          const platform_label = response.offer.payment_details.platform;
          response.offer.payment_details.method = platform_label.substring(0, index);
          response.offer.payment_details.label = platform_label.substring(index + 1);
        }

        setOfferHistory((prev) => offfeerHistory.data);
        setOfferDetails(response.offer);
        // SetOfferMessages(OfferMessages)
        setOfferProducts(response.offer.offers_products);
      }
      if (result) {
        setMessageList((prev) => result.data);
      }
      setHistoryLength((prev) => {
        return {
          ...prev,
          activityLength: offfeerHistory.data.length,
          messageLength: result.data.length,
        };
      });
    } catch (err) {}
  };

  const downloadHandler = async (filePath) => {
    try {
      // download(filePath);
      window.open(filePath, "_blank");
    } catch (err) {
      return false;
    }
  };
  return (
    <div className="page-wrapper">
      <section>
        <ToastContainer />
      </section>

      <section className="sptb">
        {!contentLoaded ? (
          <div className="loadingImg">
            <img src="/assets/images/loading.gif" className="text-center" />
          </div>
        ) : (
          <div className="container">
            <div className="page-header-wrapper row">
              <p className="col-1">
                <b>#INV-</b>
                {offerId}
              </p>
              <h3 className="col-10"> Preview Offer </h3>
              <button
                className="btn btn-secondary remove-items-btn col-1 ms-3 payment-btn-height mt-0"
                onClick={() => navigate("/offerlist")}
              >
                Back
              </button>
            </div>
            <div className="row mt-3">
              <div className="col-lg-6 ">
                <p className="h6">Client Info</p>
                <address>
                  {userDetails?.first_name} {userDetails?.last_name}
                  <br />
                  {userDetails?.address_1}
                  <br />
                  {userDetails?.state}, {userDetails?.city}
                  <br />
                  {userDetails?.email}
                </address>
              </div>
              <div className="col-lg-6 text-end">
                <p className="h6">To</p>
                <address>
                  {shippingDetails?.first_name} {shippingDetails?.last_name}
                  <br />
                  {shippingDetails?.street_address}
                  <br />
                  {shippingDetails?.state}, {shippingDetails?.city}
                  <br />
                  {shippingDetails?.postal_code}
                  <br />
                  {shippingDetails?.email}
                </address>
              </div>
            </div>
            <div className="text-dark">
              <p className="mb-3 mt-5 mx-3">
                <span className="font-weight-bold">Offer Date :</span>{" "}
                {moment(offer?.createdAt?.split("T")[0]).format("MM-DD-YYYY")}
              </p>
            </div>
            <div className="row d-flex justify-content-center" style={{ marginTop: 20, marginBottom: 20 }}>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="m-1">
                  <div className="border rounded p-3">
                    <table className="table table-borderless">
                      <thead>
                        <th>Product Name</th>
                        <th>Brand</th>
                        <th>Model</th>
                        <th>UPC</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Total</th>
                      </thead>
                      <tbody>
                        {offerProducts?.map((data, index) => {
                          return (
                            <>
                              <tr>
                                <td>{data.product_name}</td>
                                <td>{data.brand}</td>
                                <td>{data.model_num}</td>
                                <td>{data.upc}</td>
                                <td rowSpan="2">{data.quantity}</td>
                                <td rowSpan="2">{data.price}</td>
                                <td rowSpan="2">{data.quantity * data.price}</td>
                              </tr>
                              <tr className={index === offerProducts.length - 1 ? null : "border-bottom"}>
                                <td colSpan="3">{data.description}</td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* <hr class="opacity-100" />
              <h3 className="my-4 text-center">Messages</h3>
                 {Array.isArray(OfferMessages) ? (
                  <>
                    {OfferMessages?.map((messages) => {
                      return (
                        <div>
                          <div className="media ps-5 pe-5 pb-1 pt-3 mt-0">
                            <div className="d-flex me-3">
                              {user.image ? (
                                <img
                                  className="media-object brround"
                                  //   alt="64x64"
                                  src={user.image ? user.image : null}
                                />
                              ) : (
                                <span className="rounded-circle bg-dark text-white p-2">
                                  {messages.send_by === "seller" ? "SE" : user.avatar}
                                </span>
                              )}
                              <h4 className="mt-1 mb-1 ms-2">
                                {messages.send_by === "seller"
                                  ? "seller"
                                  : user.Name}
                                <span
                                  className="fs-12 ms-0"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title
                                  data-bs-original-title="verified"
                                >
                                  <i className="fa fa-check-circle-o text-success" />
                                </span>
                              </h4>
                            </div>
                            <div className="media-body ms-5">
                              
                              <small className="text-muted">
                                <i className="fa fa-calendar" />{" "}
                                {messages.send_date}
                                <i className=" ms-3 fa fa-clock-o" />{" "}
                                {messages.send_time}
                                <i className=" ms-3 fa fa-map-marker" /> UK
                              </small>
                              <p className="fs-15  mb-2 mt-2">
                                {messages.message}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : null} */}
                <hr class="opacity-100" />
                <div className="m-4 mt-1">
                  <div className="mb-2">
                    <h4 className="form-label">Images</h4>
                    {offer.images?.map((image) => {
                      return (
                        <>
                          <img
                            src={image}
                            className="mx-2"
                            style={{ width: "150px", height: "150px" }}
                            onClick={(e) => {
                              downloadHandler(e.target.src);
                            }}
                          />
                        </>
                      );
                    })}
                  </div>
                  {offer.images_after_delivery && offer.images_after_delivery.length > 0 ? (
                    <div className="mb-2">
                      <h4 className="form-label">Images After Delivery</h4>
                      {offer.images_after_delivery?.map((image) => {
                        return (
                          <>
                            <img
                              src={image}
                              className="mx-2"
                              style={{ width: "150px", height: "150px" }}
                              onClick={(e) => {
                                downloadHandler(e.target.src);
                              }}
                            />
                          </>
                        );
                      })}
                    </div>
                  ) : null}
                  <h4 className="form-label mt-1">Remarks</h4>
                  <h5 className="fs-6 fw-light">{offer.customerRemarks}</h5>
                </div>
                <hr class="opacity-100" />
                <div className="d-flex justify-content-between p-4 ">
                  <div className="border rounded p-3 w-40">
                    <div className="d-flex justify-content-between ">
                      <p>Offer Status : </p>
                      <p>{offer.offer_status?.toUpperCase().replace("-", " ")}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>Payment Status : </p>
                      <p>{offer.payment_status}</p>
                    </div>
                  </div>
                  <div className="border rounded p-4 w-40">
                    <div className="d-flex justify-content-between ">
                      <p>{offer.expense_field}</p>
                      <p>{offer.expense_value}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>Total Amount :</p>
                      <p>{offer.total_price}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <h4 className="form-label mt-1 ms-1">Selected Payout Method</h4>
                  <p className="ms-3">
                    {offer.payment_details?.platform}({offer.payment_details?.details})
                  </p>
                </div>
                <hr class="opacity-100" />
                {id && (
                  <>
                    <div className="row border">
                      <div
                        className="col-12 d-flex justify-content-between  mt-0 bg-secondary bg-opacity-10"
                        onClick={() => setShowActHis((prev) => !prev)}
                      >
                        <div className="fs-5">
                          Activity History ( {historyLength.activityLength ? historyLength.activityLength : "0"} )
                        </div>
                        <div>{showActHis ? <span className="fs-5">-</span> : <span className="fs-5">+</span>}</div>
                      </div>
                      <div>
                        {showActHis && (
                          <div style={{ overflowY: "scroll", maxHeight: "200px" }} className="my-2">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                              <div className="m-1">
                                <div className="border rounded p-3">
                                  <table className="table table-borderless">
                                    <thead>
                                      <th>Change</th>
                                      <th>User</th>
                                      <th>Created At</th>
                                    </thead>
                                    <tbody>
                                      {offerHistory?.length > 0 &&
                                        offerHistory?.map((data, index) => {
                                          {
                                            offerHistory && offerHistory.length > 0;
                                            return (
                                              <tr>
                                                <td
                                                  style={{ padding: "0", maxWidth: "550px" }}
                                                  dangerouslySetInnerHTML={{ __html: data?.log_message }}
                                                ></td>
                                                <td style={{ padding: "0px 2px" }}>
                                                  {data?.log_by_user.first_name} {data?.log_by_user.last_name} (
                                                  {data?.log_by_user.email})
                                                </td>
                                                {/* <td>{data?.remarks}</td> */}
                                                <td style={{ padding: "0" }}>
                                                  {moment(data?.createdAt?.split("T")[0]).format("MM-DD-YYYY")}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="col-12  d-flex justify-content-between mb-0  bg-secondary bg-opacity-10"
                        onClick={() => setShowMsgHis((prev) => !prev)}
                      >
                        <div className="fs-5">
                          Message History ( {historyLength.messageLength ? historyLength.messageLength : "0"} )
                        </div>
                        <div>{showMsgHis ? <span className="fs-5">-</span> : <span className="fs-5">+</span>}</div>
                      </div>
                      <div>
                        {showMsgHis && (
                          <>
                            <div style={{ overflowY: "scroll", maxHeight: "200px" }} className="my-2">
                              <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="m-1">
                                  <div className="border rounded p-3">
                                    <table className="table table-borderless">
                                      <thead>
                                        <th>User</th>
                                        <th>Message</th>
                                        <th>Timestamp</th>
                                      </thead>
                                      <tbody>
                                        {Array.isArray(messageList) &&
                                          messageList?.map((messages) => {
                                            return (
                                              <tr>
                                                <td style={{ padding: "0" }}>{messages?.user}</td>
                                                <td style={{ padding: "0" }}>{messages?.message}</td>
                                                <td style={{ padding: "0" }}>
                                                  {moment(messages?.send_date).format("MM-DD-YYYY")}{" "}
                                                  {messages?.send_time}{" "}
                                                </td>
                                              </tr>
                                            );
                                          })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
    //   </section>
    // </div>
  );
}
export default PreviewOffers;
