import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LinkName from "../LinkName/LinkName";
import Pagination from "react-bootstrap/Pagination";
import OfferService from "../../services/OfferService";
import util from "../../util/util";
import moment from "moment-timezone";
import * as Yup from "yup";
import { useFormik, Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const offerStatus = [
  { value: "", label: "Offers status" },
  { value: "revised", label: "Revised" },
  { value: "closed", label: "Closed" },
  { value: "rejected", label: "Rejected" },
  { value: "approved", label: "Approved" },
  { value: "not-approved", label: "Not approved" },
  { value: "shipped", label: "Shipped" },
  { value: "delivered", label: "Delivered" },
  { value: "draft", label: "Draft" },
  { value: "sent", label: "Sent" },
  { value: "cancelled", label: "Cancelled" },
  { value: "delivered-with-defects", label: "Delivered With Defects" },
];
const ValidateSchema = Yup.object({
  tracking_num: Yup.string().required("Required"),
  shipment_company: Yup.string().required("Required"),
});
export default function OfferList() {
  const offerServ = new OfferService();
  const [offerList, setOfferList] = useState([]);
  const [showAction, setShowAction] = useState({
    open: false,
    index: false,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [curr, setCurr] = useState({ colHead: null, directionValue: null });
  const navigate = useNavigate();
  const [showTracking, setShowTracking] = useState({ isOpen: false, id: "" });
  const [showRemarks, setShowRemarks] = useState({
    isOpen: false,
    id: "",
    customerRemarks: "",
    adminRemarks: "",
    copyContent: "",
    invoiceNum: "",
  });
  // const [modalOpen, setModalOpen] = useState(false);
  const [trackingDetails, setTrackingDetails] = useState({
    tracking_num: "",
    shipment_company: "",
    tracking_url: "",
  });

  const searchState = {
    start: 0,
    perPage: 10,
    filter: {
      user_id: "",
      offerStatus: "",
      paymentInfo: "",
      searchName: "",
      searchEmail: "",
      searchUpc: "",
      searchBrand: "",
      from_date: "",
      to_date: "",
    },
    sortBy: { createdAt: "desc" },
    filterByRange: false,
    daysFilterLabel: "",
  };
  if (searchParams.get("s")) {
    let s = searchParams.get("s");
    if (s.includes("||")) {
      s = s.split("||");
      searchState.filter.offerStatus = s[1];
      searchState.filter.paymentInfo = s[0];
    } else {
      searchState.filter.offerStatus = searchParams.get("s");
    }
  }
  if (searchParams.get("d")) {
    if (searchParams.get("d") == "this_year") {
      searchState.filter.days = moment().dayOfYear();
      searchState.daysFilterLabel = "this_year";
    } else {
      searchState.filter.days = parseInt(searchParams.get("d"));
      searchState.daysFilterLabel = searchState.filter.days;
    }
  }
  if (searchParams.get("sd")) {
    searchState.filter.from_date = moment(searchParams.get("sd")).format("YYYY-MM-DD");
  }
  if (searchParams.get("ed")) {
    searchState.filter.to_date = moment(searchParams.get("ed")).format("YYYY-MM-DD");
  }
  if (searchParams.get("u") && searchParams.get("u") != "undefined") {
    searchState.filter.user_id = parseInt(searchParams.get("u"));
  }

  const [search, setSearch] = useState(searchState);

  function handleStartDateChange(date) {
    let tempSearch = { ...search };
    tempSearch.filter.from_date = date;
    tempSearch.start = 0;
    setSearch(tempSearch);
  }

  function handleEndDateChange(date) {
    let tempSearch = { ...search };
    tempSearch.filter.to_date = date;
    tempSearch.start = 0;
    setSearch(tempSearch);
  }

  function resetFilter() {
    let tempSearch = { ...search };
    tempSearch.filter.offerStatus = "";
    tempSearch.filter.paymentInfo = "";
    tempSearch.filter.days = "";
    tempSearch.filter.user_id = "";
    tempSearch.filter.searchName = "";
    tempSearch.filter.searchEmail = "";
    tempSearch.filter.searchUpc = "";
    tempSearch.filter.searchBrand = "";
    tempSearch.filter.from_date = "";
    tempSearch.filter.to_date = "";
    tempSearch.daysFilterLabel = "";
    tempSearch.filterByRange = false;
    tempSearch.start = 0;

    setSearch(tempSearch);
  }
  async function getOffersListApi() {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {},
      sortBy: search.sortBy,
    };
    activity.filter.offer_status = search.filter.offerStatus;
    activity.filter.payment_status = search.filter.paymentInfo;
    activity.filter.days = search.filter.days;
    activity.filter.name = search.filter.searchName;
    activity.filter.email = search.filter.searchEmail;
    activity.filter.upc = search.filter.searchUpc;
    activity.filter.brand = search.filter.searchBrand;
    activity.filter.from_date = search.filter.from_date
      ? util.formatDateAsREACT_APP_MOMENT_TIME_ZONE(search.filter.from_date) + " 00:00:00"
      : "";
    activity.filter.to_date = search.filter.to_date
      ? util.formatDateAsREACT_APP_MOMENT_TIME_ZONE(search.filter.to_date) + " 23:59:59"
      : "";
    activity.filter.user_id = search.filter.user_id;
    try {
      let response = await offerServ.listApi(activity);
      if (response) {
        setContentLoaded(true);
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].createdAt = moment(response.data[i].createdAt)
            .tz(process.env.REACT_APP_MOMENT_TIME_ZONE)
            .format("MM-DD-YYYY HH:mm:ss");
        }
        setOfferList(response.data);

        setTotalCount(response.total);
      } else {
        setOfferList([]);
        setTotalCount(0);
      }
    } catch (err) {
      throw err;
    }
  }

  async function changeOfferStatus(e) {
    let tempSearch = { ...search };
    tempSearch.filter.offerStatus = e.target.value;
    tempSearch.start = 0;
    setSearch(tempSearch);
  }

  async function changePaymentInfo(e) {
    let tempSearch = { ...search };
    tempSearch.filter.paymentInfo = e.target.value;
    tempSearch.start = 0;
    setSearch(tempSearch);
  }

  async function DaysFilter(e) {
    let tempSearch = { ...search };
    tempSearch.filterByRange = false;
    tempSearch.daysFilterLabel = e.target.value;
    if (e.target.value == "custom") {
      tempSearch.filter.days = "";
      tempSearch.filterByRange = true;
    } else if (e.target.value == "this_year") {
      let Difference_In_Days = moment().dayOfYear();
      tempSearch.filter.days = Difference_In_Days;
      tempSearch.filter.from_date = "";
      tempSearch.filter.to_date = "";
    } else {
      tempSearch.filter.days = e.target.value ? parseInt(e.target.value) : "";
      tempSearch.filter.from_date = "";
      tempSearch.filter.to_date = "";
    }
    tempSearch.start = 0;
    setSearch(tempSearch);
  }

  const handlePaging = (e) => {
    let tempSearch = { ...search };
    tempSearch.start = parseInt(e.target.text) * search.perPage - search.perPage;
    setSearch(tempSearch);
  };

  let active = Math.ceil((search.start + 1) / search.perPage);
  let pages = Math.ceil(totalCount / search.perPage);

  let items = [];
  for (let number = 1; number <= pages; number++) {
    items.push(
      <Pagination.Item key={number} onClick={handlePaging} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  useEffect(() => {
    getOffersListApi();
  }, [searchParams, search]);

  const copyContent = (products) => {
    let value = { index: -1, open: true };
    setShowAction(value);
    let ProductTxt = [];
    products.forEach((element) => {
      ProductTxt.push(`${element.product_name} UPC : ${element.upc} - ${element.quantity}(${element.price})
`);
    });
    ProductTxt = ProductTxt.join(",");
    ProductTxt = ProductTxt.replace(",", "");
    const textToSave = ProductTxt;
    navigator.clipboard.writeText(textToSave);
    return textToSave;
  };
  const handleSort = (condition) => {
    const conditionArray = condition.split("-");
    let field = conditionArray[0];
    let direction = conditionArray[1];
    if (direction === "none") {
      direction = "asc";
    } else if (direction === "asc") {
      direction = "desc";
    } else {
      direction = "none";
    }

    const tempSearch = { ...search };
    let newSort = {};
    newSort[field] = direction;
    tempSearch.sortBy = newSort;
    tempSearch.start = 0;

    setCurr({
      colHead: field,
      directionValue: direction,
    });

    if (direction === "none") {
      tempSearch.sortBy = undefined;
      setSearch(tempSearch);
    } else {
      setSearch(tempSearch);
    }
  };
  const CloneOffer = async (key) => {
    try {
      if (window.confirm(`Do you want to clone this Offer #${key.id}`)) {
        let response = await offerServ.clone(key.id);
        if (response.message) {
          getOffersListApi();
          toast.success(response.message);
          setTotalCount(totalCount - 1);
        } else {
          toast.error("Offer not cloned");
        }
      }
    } catch (err) {
      throw err;
    }
  };

  function changeSearchFieled(field, e) {
    let tempSearch = { ...search };
    tempSearch.start = 0;
    tempSearch.filter[field] = e.target.value;
    setSearch(tempSearch);
  }

  const previewContent = (id) => {
    navigate(`/offer/preview/${id}`);
  };

  const downloadPdf = async (key) => {
    try {
      let response = await offerServ.GetPdf(key.id);
      window.open(response.data);
    } catch (err) {
      throw err;
    }
  };

  const deleteOffer = async (offerId) => {
    try {
      if (window.confirm(`Are you sure you want to delete this Offer #${offerId} ?`)) {
        let response = await offerServ.deleteOffer(offerId);
        if (response.message) {
          getOffersListApi();
          toast.success(response.message);
          setTotalCount(totalCount - 1);
        } else {
          toast.error("Offer not deleted ");
        }
      }
    } catch (err) {
      throw err;
    }
  };

  const addNewOffer = () => {
    navigate("/createoffer");
  };
  const paymentDetails = (offerId, userId) => {
    navigate(`/paymentdetails/${offerId}/${userId}`);
  };
  const sendMessage = (offerId) => {
    navigate("/message/" + offerId);
  };

  const handleTrackingInfo = (offerid) => {
    const trackingDetails = offerList.filter((value) => {
      return offerid === value.id;
    });
    if (trackingDetails) {
      const trackingInfo = {
        shipment_company: trackingDetails[0].shipment_company,
        tracking_num: trackingDetails[0].tracking_num,
        tracking_url: trackingDetails[0].tracking_url,
      };
      setTrackingDetails((prev) => trackingInfo);
      setShowTracking((prev) => {
        return {
          isOpen: true,
          id: offerid,
        };
      });
    }
  };
  const trackingSubmit = async (values) => {
    let payload = { ...values };
    payload.id = showTracking.id;

    let result = await offerServ.setTrackingInfo(payload);
    if (result.message) {
      toast.success(result.message);
      setShowTracking((prev) => {
        return { ...prev, isOpen: false };
      });
    }
  };
  const formik = useFormik({
    initialValues: trackingDetails,
    onSubmit: trackingSubmit,
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: ValidateSchema,
  });
  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };
  return (
    <div
      className="ljSectionData w-100 clearfix ljpUserTables pb-4 p-relative"
      id="ljpUserTables"
      onClick={() => {
        if (showAction.open) {
          setShowAction({ open: false, index: showAction.index });
        }
      }}
    >
      <LinkName heading="Offer Database" image="/assets/images/icons/user.png" link="/user_table" />
      {showTracking.isOpen == true && (
        <>
          <div className="modal d-block mt-5 pt-5" tabIndex={-1} role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Tracking Details</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() =>
                      setShowTracking((prev) => {
                        return { ...prev, isOpen: false };
                      })
                    }
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Formik>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="form-label">Tracking number</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Tracking number"
                              value={formik.values.tracking_num}
                              name="tracking_num"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched &&
                            formik.touched.tracking_num &&
                            formik.errors &&
                            formik.errors?.tracking_num
                              ? dispErrorMsg("Required")
                              : null}
                          </div>
                        </div>
                        <div className="col-12 my-2">
                          <div className="form-group">
                            <label className="form-label">Shipment company</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Shipment company"
                              value={formik.values.shipment_company}
                              name="shipment_company"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched &&
                            formik.touched.shipment_company &&
                            formik.errors &&
                            formik.errors?.shipment_company
                              ? dispErrorMsg("Required")
                              : null}
                          </div>
                        </div>
                        <div className="col-12 mb-1">
                          <div className="form-group">
                            <label className="form-label">Tracking Url</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Tracking Url"
                              value={formik.values.tracking_url}
                              name="tracking_url"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched &&
                            formik.touched.tracking_url &&
                            formik.errors &&
                            formik.errors?.tracking_url
                              ? dispErrorMsg("Required")
                              : null}
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-primary">
                            Ok
                          </button>
                        </div>
                      </div>
                    </form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop show"></div>
        </>
      )}
      {showRemarks.isOpen == true && (
        <>
          <div className="modal d-block mt-5 pt-5" tabIndex={-1} role="dialog">
            <div className="modal-dialog" role="document" style={{ maxWidth: "50vw" }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Remarks Details</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() =>
                      setShowRemarks((prev) => {
                        return { ...prev, isOpen: false };
                      })
                    }
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row mb-2">
                    <div className="col-4">
                      <b>Invoice No. :</b>
                    </div>
                    <div className="col-8">{showRemarks.invoiceNum}</div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-4">
                      <b>Customer Remarks :</b>
                    </div>
                    <div className="col-8">{showRemarks.customerRemarks}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <b>Admin Remarks :</b>
                    </div>
                    <div className="col-8">{showRemarks.adminRemarks}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <b>Copy Content :</b>
                    </div>
                    <div className="col-8" style={{ whiteSpace: "pre-line" }}>
                      {showRemarks.copyContent}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop show"></div>
        </>
      )}
      {!contentLoaded ? (
        <div className="loadingImg">
          <img src="/assets/images/loading.gif" className="text-center" />
        </div>
      ) : (
        <div className="user_part">
          <>
            <div className="users_bottom_part">
              <div
                className="d-flex flex-wrap user_bottom_header align-items-center margintxt"
                style={{ justifyContent: "space-between" }}
              >
                <div className=" text-heading">
                  <h4 className="mb-0">All Offers</h4>
                </div>
                <div>
                  <button type="primary" className="add-user-btn" onClick={() => addNewOffer()}>
                    Add New Offer
                  </button>
                </div>
              </div>
              <div className="row px-2 ">
                <div className="col-md-2 col-sm-6 ">
                  <div className="form-group form-group-margin-bottom">
                    <select
                      className="form-control "
                      // style={{ width: "40%" }}
                      onChange={(e) => changeOfferStatus(e)}
                      value={search.filter.offerStatus}
                    >
                      {offerStatus.map((st) => {
                        return <option value={st.value}>{st.label} </option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-2 col-sm-6 ">
                  <div className="form-group form-group-margin-bottom">
                    <select
                      className="form-control w-40"
                      // style={{ width: "40%" }}
                      onChange={(e) => changePaymentInfo(e)}
                      value={search.filter.paymentInfo}
                    >
                      <option value="">Payment status</option>
                      <option value="paid">Paid</option>
                      <option value="unpaid">Unpaid</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2 col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search name"
                    onChange={(e) => {
                      changeSearchFieled("searchName", e);
                    }}
                    value={search.filter.searchName}
                  />
                </div>
                <div className="col-md-2 col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search email"
                    onChange={(e) => {
                      changeSearchFieled("searchEmail", e);
                    }}
                    value={search.filter.searchEmail}
                  />
                </div>
                <div className="col-md-2 col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search UPC"
                    onChange={(e) => {
                      changeSearchFieled("searchUpc", e);
                    }}
                    value={search.filter.searchUpc}
                  />
                </div>
                <div className="col-md-2 col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search brand"
                    onChange={(e) => {
                      changeSearchFieled("searchBrand", e);
                    }}
                    value={search.filter.searchBrand}
                  />
                </div>
              </div>
              <div className="row px-2 ">
                <div className="col-md-2  col-sm-6">
                  <select onChange={(e) => DaysFilter(e)} className="form-control" value={search.daysFilterLabel}>
                    <option className="p-1" value="">
                      Days filter
                    </option>
                    <option className="p-1" value="1">
                      Today
                    </option>
                    <option className="p-1" value="7">
                      7 days
                    </option>
                    <option className="p-1" value="30">
                      30 days
                    </option>
                    <option className="p-1" value="60">
                      60 days
                    </option>
                    <option className="p-1" value="90">
                      90 days
                    </option>
                    <option className="p-1" value="this_year">
                      This year
                    </option>
                    <option className="p-1" value="custom">
                      Custom range
                    </option>
                  </select>
                </div>
                {search.filterByRange && (
                  <div className="col-md-2 col-sm-6">
                    <DatePicker
                      selected={search.filter.from_date}
                      placeholderText="Start Date"
                      onChange={(date) => handleStartDateChange(date)}
                      className="form-control"
                      maxDate={search.filter.to_date}
                    />
                  </div>
                )}
                {search.filterByRange && (
                  <div className="col-md-2 col-sm-6">
                    <DatePicker
                      selected={search.filter.to_date}
                      placeholderText="End Date"
                      onChange={(date) => handleEndDateChange(date)}
                      className="form-control"
                      minDate={search.filter.from_date}
                    />
                  </div>
                )}
                <div className="col-md-2 col-sm-6">
                  <button
                    type="button"
                    className="btn btn-secondary remove-items-btn"
                    placeholder="Reset"
                    onClick={resetFilter}
                  >
                    Reset
                  </button>
                </div>
              </div>

              <div className="users_table mt-3">
                <div className="current_courses_table table-responsive">
                  <table className="main_table w-100">
                    <thead>
                      <tr>
                        <th>
                          <div className="d-flex justify-content-between">
                            <div>ID</div>
                            <div className="text-center ">
                              {curr?.colHead === "id" && curr?.directionValue === "asc" ? (
                                <i className="fa fa-caret-up" onClick={() => handleSort("id-asc")}></i>
                              ) : null}
                              {curr?.colHead === "id" && curr?.directionValue === "desc" ? (
                                <i className="fa fa-caret-down" onClick={() => handleSort("id-desc")}></i>
                              ) : null}
                              {curr.colHead === "id" && curr.directionValue === "none" ? (
                                <i className="fa fa-sort opacity-25" onClick={() => handleSort("id-none")}></i>
                              ) : null}
                              {curr.colHead !== "id" ? (
                                <i className="fa fa-sort opacity-25" onClick={() => handleSort("id-none")}></i>
                              ) : null}
                            </div>
                          </div>
                        </th>
                        <th>
                          <div className="d-flex justify-content-between">
                            <div>Added by user</div>
                            <div className="text-center ">
                              {curr.colHead === "first_name" && curr.directionValue === "asc" ? (
                                <span className="fa fa-caret-up" onClick={() => handleSort("first_name-asc")}></span>
                              ) : null}
                              {curr.colHead === "first_name" && curr.directionValue === "desc" ? (
                                <span className="fa fa-caret-down" onClick={() => handleSort("first_name-desc")}></span>
                              ) : null}
                              {curr.colHead === "first_name" && curr.directionValue === "none" ? (
                                <i onClick={() => handleSort("first_name-none")} className="fa fa-sort opacity-25"></i>
                              ) : null}
                              {curr.colHead != "first_name" ? (
                                <i className="fa fa-sort opacity-25" onClick={() => handleSort("first_name-none")}></i>
                              ) : null}
                            </div>
                          </div>
                        </th>
                        <th>
                          <div className="d-flex justify-content-between">
                            <div>Remarks</div>
                          </div>
                        </th>
                        <th>
                          <div className="d-flex justify-content-between">
                            <div>Total Amount</div>
                            <div className="text-center ">
                              {curr.colHead === "total_price" && curr.directionValue === "asc" ? (
                                <span className="fa fa-caret-up" onClick={() => handleSort("total_price-asc")}></span>
                              ) : null}
                              {curr.colHead === "total_price" && curr.directionValue === "desc" ? (
                                <span
                                  className="fa fa-caret-down"
                                  onClick={() => handleSort("total_price-desc")}
                                ></span>
                              ) : null}
                              {curr.colHead === "total_price" && curr.directionValue === "none" ? (
                                <i className="fa fa-sort opacity-25" onClick={() => handleSort("total_price-none")}></i>
                              ) : null}
                              {curr.colHead != "total_price" ? (
                                <i onClick={() => handleSort("total_price-none")} className="fa fa-sort opacity-25"></i>
                              ) : null}
                            </div>
                          </div>
                        </th>
                        <th>
                          <div className="d-flex justify-content-between">
                            <div>Offer Status</div>
                            <div className="text-center ">
                              {curr.colHead == "offer_status" && curr.directionValue == "asc" ? (
                                <span className="fa fa-caret-up" onClick={() => handleSort("offer_status-asc")}></span>
                              ) : null}
                              {curr.colHead == "offer_status" && curr.directionValue == "desc" ? (
                                <span
                                  className="fa fa-caret-down"
                                  onClick={() => handleSort("offer_status-desc")}
                                ></span>
                              ) : null}
                              {curr.colHead === "offer_status" && curr.directionValue == "none" ? (
                                <i
                                  onClick={() => handleSort("offer_status-none")}
                                  className="fa fa-sort opacity-25"
                                ></i>
                              ) : null}
                              {curr.colHead != "offer_status" ? (
                                <i
                                  onClick={() => handleSort("offer_status-none")}
                                  className="fa fa-sort opacity-25"
                                ></i>
                              ) : null}
                            </div>
                          </div>
                        </th>
                        <th>
                          <div className="d-flex justify-content-between">
                            <div>Payment Status</div>
                            <div className="text-center ">
                              {curr.colHead === "payment_status" && curr.directionValue === "asc" ? (
                                <span
                                  className="fa fa-caret-up"
                                  onClick={() => handleSort("payment_status-asc")}
                                ></span>
                              ) : null}
                              {curr.colHead === "payment_status" && curr.directionValue === "desc" ? (
                                <span
                                  className="fa fa-caret-down"
                                  onClick={() => handleSort("payment_status-desc")}
                                ></span>
                              ) : null}
                              {curr.colHead === "payment_status" && curr.directionValue === "none" ? (
                                <span
                                  className="fa fa-sort opacity-25"
                                  onClick={() => handleSort("payment_status-none")}
                                ></span>
                              ) : null}
                              {curr.colHead !== "payment_status" ? (
                                <span
                                  className="fa fa-sort opacity-25"
                                  onClick={() => handleSort("payment_status-none")}
                                ></span>
                              ) : null}
                            </div>
                          </div>
                        </th>
                        <th>
                          <div className="d-flex justify-content-between">
                            <div className="text-center">
                              Payment Method <br />
                              (Zelle/Paypal)
                            </div>
                            <div className="text-center ">
                              {curr.colHead === "payment_method" && curr.directionValue === "asc" ? (
                                <span
                                  className="fa fa-caret-up"
                                  onClick={() => handleSort("payment_method-asc")}
                                ></span>
                              ) : null}
                              {curr.colHead === "payment_method" && curr.directionValue === "desc" ? (
                                <span
                                  className="fa fa-caret-down"
                                  onClick={() => handleSort("payment_method-desc")}
                                ></span>
                              ) : null}
                              {curr.colHead === "payment_method" && curr.directionValue === "none" ? (
                                <span
                                  className="fa fa-sort opacity-25"
                                  onClick={() => handleSort("payment_method-none")}
                                ></span>
                              ) : null}
                              {curr.colHead !== "payment_method" ? (
                                <span
                                  className="fa fa-sort opacity-25"
                                  onClick={() => handleSort("payment_method-none")}
                                ></span>
                              ) : null}
                            </div>
                          </div>
                        </th>
                        <th>
                          <div className="d-flex justify-content-between">
                            <div>Created At</div>
                            <div className="text-center ">
                              {curr.colHead === "createdAt" && curr.directionValue === "asc" ? (
                                <span className="fa fa-caret-up" onClick={() => handleSort("createdAt-asc")}></span>
                              ) : null}
                              {curr.colHead === "createdAt" && curr.directionValue === "desc" ? (
                                <span className="fa fa-caret-down" onClick={() => handleSort("createdAt-desc")}></span>
                              ) : null}
                              {curr.colHead === "createdAt" && curr.directionValue === "none" ? (
                                <span
                                  className="fa fa-sort opacity-25"
                                  onClick={() => handleSort("createdAt-none")}
                                ></span>
                              ) : null}
                              {curr.colHead !== "createdAt" ? (
                                <span
                                  className="fa fa-sort opacity-25"
                                  onClick={() => handleSort("createdAt-none")}
                                ></span>
                              ) : null}
                            </div>
                          </div>
                        </th>
                        <th>
                          <div className="d-flex justify-content-between">
                            <div className="hands-icon">
                              <span className="fa fa-comment p-1 me-1"></span>
                            </div>
                            <div className="text-center ">
                              {curr.colHead === "readCount" && curr.directionValue === "asc" ? (
                                <span className="fa fa-caret-up" onClick={() => handleSort("readCount-asc")}></span>
                              ) : null}
                              {curr.colHead === "readCount" && curr.directionValue === "desc" ? (
                                <span className="fa fa-caret-down" onClick={() => handleSort("readCount-desc")}></span>
                              ) : null}
                              {curr.colHead === "readCount" && curr.directionValue === "none" ? (
                                <i className="fa fa-sort opacity-25" onClick={() => handleSort("readCount-none")}></i>
                              ) : null}
                              {curr.colHead != "readCount" ? (
                                <i onClick={() => handleSort("readCount-none")} className="fa fa-sort opacity-25"></i>
                              ) : null}
                            </div>
                          </div>
                        </th>
                        <th className="action_icons position-relative" style={{ minWidth: "205px" }}>
                          ACTIONS
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {offerList && offerList.length > 0 */}
                      {offerList.map((key, i) => {
                        {
                          offerList && offerList.length > 0;
                          return (
                            <tr key={i}>
                              <td>
                                <Link to={`/createoffer/${key.id}`}>
                                  <td className="text-primary"> {key.id} </td>
                                </Link>
                              </td>
                              <td>{key.user ? `${key.user.first_name} ${key.user.last_name}` : ""}</td>
                              <td>
                                <span
                                  className="fa fa-solid fa-message p-1 me-1"
                                  onClick={() => {
                                    setShowRemarks({
                                      isOpen: true,
                                      customerRemarks: key.customerRemarks,
                                      adminRemarks: key.remarks,
                                      copyContent: copyContent(key.offers_products),
                                      invoiceNum: key.id,
                                    });
                                  }}
                                ></span>
                              </td>
                              <td>
                                {key.total_price ? key.total_price : <span style={{ color: "red" }}> Not Found </span>}
                              </td>
                              <td>
                                {key.offer_status ? (
                                  key.offer_status.toUpperCase().replaceAll("-", " ")
                                ) : (
                                  <span style={{ color: "red" }}> Not Found </span>
                                )}
                              </td>
                              <td>
                                {key.payment_status ? (
                                  key.payment_status.toUpperCase()
                                ) : (
                                  <span style={{ color: "red" }}> Not Found </span>
                                )}
                              </td>
                              <td>
                                {key.payment_info ? (
                                  key.payment_info?.platform
                                ) : (
                                  <span style={{ color: "red" }}> Not Found </span>
                                )}
                              </td>
                              <td>
                                {key.createdAt ? key.createdAt : <span style={{ color: "red" }}> Not Found </span>}
                              </td>
                              <td>
                                <Link className="text-primary" to={"/message/" + key.id}>
                                  <span className="fa fa-comment p-1 me-1 chaticon">
                                    {key.readCount ? <span>{key.readCount}</span> : ""}
                                  </span>
                                </Link>
                              </td>
                              <td style={{ position: "relative" }}>
                                <i
                                  className="fa fa-ellipsis-v p-2 hands-icon"
                                  aria-hidden="true"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowAction((prev) => {
                                      return { open: !prev.open, index: i };
                                    });
                                  }}
                                ></i>
                                {showAction.open && showAction.index == i && (
                                  <>
                                    <div className="border rounded z-10 position-absolute top-10  end-30">
                                      <ul className="list-group bg-white ">
                                        <li
                                          className="mx-3 my-1 border-0 m-0 d-flex jusity-content-around hands-icon"
                                          onClick={() => previewContent(key.id)}
                                        >
                                          <span className="fa me-1  fa-eye action-icons eye-btn"></span>

                                          <span className="text-primary fw-light ms-3">Preview Offer</span>
                                        </li>
                                        <li
                                          className="mx-3 my-1 border-0 m-0 d-flex jusity-content-around hands-icon"
                                          onClick={() => copyContent(key.offers_products)}
                                        >
                                          <span className="fa me-1 fa-copy action-icons"></span>

                                          <span className="text-primary fw-light ms-3"> Copy Content</span>
                                        </li>
                                        <li
                                          className="mx-3 my-1 border-0 m-0 d-flex jusity-content-around hands-icon"
                                          onClick={() => {
                                            setShowAction((prev) => {
                                              return { open: !prev.open, index: i };
                                            });
                                            handleTrackingInfo(key.id);
                                          }}
                                        >
                                          <span className="fa me-1 fa-ship action-icons"></span>

                                          <span className="text-primary fw-light ms-3">Mark as Shipped</span>
                                        </li>
                                        <li
                                          className="mx-3 my-1 border-0 m-0 d-flex jusity-content-around hands-icon"
                                          onClick={() => CloneOffer(key)}
                                        >
                                          <span className="fa fa-clone action-icons me-1 fa-solid "></span>

                                          <span className="text-primary fw-light ms-3">Clone Offer</span>
                                        </li>
                                        <li
                                          className="mx-3 my-1 border-0 m-0 d-flex jusity-content-around hands-icon"
                                          onClick={() => paymentDetails(key.id, key.user_id)}
                                        >
                                          <span className="fa fa-money me-1"></span>
                                          <span className="text-primary fw-light ms-3"> Make Payment</span>
                                        </li>
                                        <li
                                          className="mx-3 my-1 border-0 m-0 d-flex jusity-content-around hands-icon"
                                          onClick={() => downloadPdf(key)}
                                        >
                                          <span
                                            className="fas fa-save action-icons me-1 fa-solid"
                                            data-toggle="modal"
                                            data-target="#myModal"
                                          ></span>

                                          <span className="text-primary fw-light ms-3 me-1"> Download Pdf</span>
                                        </li>
                                        <li
                                          className="mx-3 my-1 border-0 mt-0 d-flex jusity-content-around hands-icon"
                                          onClick={() => {
                                            navigate("/createoffer/" + key.id);
                                          }}
                                        >
                                          <span
                                            className={"editIconCustom fa-sharp me-1 fa-solid fa-pen-to-square"}
                                          ></span>

                                          <span className="text-primary fw-light ms-3"> Edit Offer</span>
                                        </li>
                                        <li
                                          className="mx-3 my-1 mt-2 border-0 d-flex jusity-content-around pe-auto hands-icon"
                                          onClick={() => {
                                            deleteOffer(key.id);
                                          }}
                                        >
                                          <span className="delIconCustom fa-sharp  fa-solid fa-trash me-1"></span>

                                          <span className="text-primary fw-light ms-3">Delete Offer</span>
                                        </li>

                                        <li
                                          className="mx-3 my-1 mb-2 border-0 d-flex jusity-content-around hands-icon"
                                          onClick={() => sendMessage(key.id)}
                                        >
                                          <span className="fa fa-comment p-1 me-1"></span>
                                          <span className="text-primary fw-light ms-2 ">Send Message</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </>
                                )}
                                {/* <div className="updates_icon p-0">
                              <Link
                                to="#"
                                onClick={() => {
                                  deleteOffer(key.id);
                                }}
                                className="delIconCustom fa-sharp me-1 fa-solid fa-trash"
                              ></Link>
                              <Link
                                to={"/createoffer/" + key.id}
                                className={"editIconCustom fa-sharp me-1 fa-solid fa-pen-to-square"}
                              ></Link>
                              <span
                                className="fa me-1 fa-clone action-icons"
                                onClick={() => copyContent(key.id)}
                              ></span>
                              <span
                                className="fa me-1  fa-eye action-icons eye-btn"
                                onClick={() => previewContent(key.id)}
                              ></span>
                              <span
                                class="fa fa-clone action-icons me-1 fa-solid "
                                onClick={() => CloneOffer(key)}
                              ></span>
                                <span
                                  class="fas fa-save action-icons me-1 fa-solid"
                                  data-toggle="modal"
                                  data-target="#myModal"
                                  onClick={() => downloadPdf(key)}
                                ></span>
                              <span
                                class="fa fa-money "
                                onClick={() => paymentDetails(key.id,key.user_id)}
                              ></span>
                               <i className="fa fa-comment ms-1" aria-hidden="true"
                                 onClick={()=>sendMessage(key.id)}
                               ></i>
                            </div> */}
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {offerList.length == 0 && (
                <div className="row mb-3">
                  <div className="col-4"></div>
                  <div className="col-4">
                    <p>No records found</p>
                  </div>
                  <div className="col-4"></div>
                </div>
              )}
              {offerList.length > 0 && (
                <div className="users_table_footer">
                  <Pagination size="sm">{items}</Pagination>
                </div>
              )}
            </div>
          </>
        </div>
      )}{" "}
      <ToastContainer />
    </div>
  );
}
