import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import OfferService from "../../services/OfferService";
import { useNavigate } from "react-router-dom";

const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
const offerServ = new OfferService();
export default function ShippingAddress() {
  const navigate = useNavigate();
  const ValidateSchema = Yup.object({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
    street_address: Yup.string().required("Required "),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    postal_code: Yup.number().required("Required"),
  });

  const [addShipping, setShipping] = new useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    state: "",
    country: "USA",
    city: "",
    street_address: "",
    postal_code: "",
  });
  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };

  const onSubmit = async (values) => {
    let obj = { ...values };
    let check = false;
    const result = await offerServ.postShipping(obj);
    // const result = await
    if (result) {
      toast.success(result.message);
      // setTimeout(() => {
      //   navigate("/offerlist");
      // }, 1500);
    }
  };

  const formik = useFormik({
    initialValues: addShipping,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  useEffect(async () => {
    const shipping = await offerServ.getShipping();
    setShipping((prev) => shipping.data[0]);
  }, []);
  return (
    <div>
      <section>
        <ToastContainer />
      </section>
      <section className="sptb">
        <div className="container">
          <div className="row d-flex justify-content-center" style={{ marginTop: 20, marginBottom: 20 }}>
            <div className="col-xl-10 col-lg-12 col-md-12">
              <form onSubmit={formik.handleSubmit}>
                <div className="card mb-0">
                  <div className="card-header d-flex justify-content-between">
                    <h3 className="card-title">Shipping Details </h3>
                    <button
                      className="btn btn-secondary remove-items-btn col-1 payment-btn-height ms-4 mt-0 border "
                      onClick={() => navigate("/offerlist")}
                    >
                      Back
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group form-group-margin-bottom">
                          <label className="form-label">First name*</label>
                          <input
                            type="text"
                            placeholder="First name"
                            onChange={formik.handleChange}
                            value={formik.values.first_name}
                            name="first_name"
                            className={
                              "form-control" +
                              (formik.touched.first_name && formik.errors.first_name ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.first_name && formik.errors.first_name
                            ? dispErrorMsg("First name is required")
                            : null}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group form-group-margin-bottom">
                          <label className="form-label">Last name*</label>
                          <input
                            type="text"
                            placeholder="Last name"
                            onChange={formik.handleChange}
                            value={formik.values.last_name}
                            name="last_name"
                            className={
                              "form-control" +
                              (formik.touched.last_name && formik.errors.last_name ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.last_name && formik.errors.last_name
                            ? dispErrorMsg("Last name is required")
                            : null}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group form-group-margin-bottom">
                          <label className="form-label">Email address*</label>
                          <input
                            type="email"
                            placeholder="Email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            name="email"
                            className={
                              "form-control" + (formik.touched.email && formik.errors.email ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.email && formik.errors.email ? dispErrorMsg("Email is required") : null}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group form-group-margin-bottom">
                          <label className="form-label">Phone number*</label>
                          <input
                            type="number"
                            placeholder="Number"
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                            name="phone"
                            className={
                              "form-control" + (formik.touched.phone && formik.errors.phone ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.phone && formik.errors.phone
                            ? dispErrorMsg("Phone number is required")
                            : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group form-group-margin-bottom">
                          <label className="form-label">Street address*</label>
                          <input
                            type="text"
                            placeholder="Address"
                            onChange={formik.handleChange}
                            value={formik.values.street_address}
                            name="street_address"
                            className={
                              "form-control" +
                              (formik.touched.street_address && formik.errors.street_address ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.street_address && formik.errors.street_address
                            ? dispErrorMsg("Address is required")
                            : null}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group form-group-margin-bottom">
                          <label className="form-label">City*</label>
                          <input
                            type="text"
                            placeholder="City"
                            onChange={formik.handleChange}
                            value={formik.values.city}
                            name="city"
                            className={
                              "form-control" + (formik.touched.city && formik.errors.city ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.city && formik.errors.city ? dispErrorMsg("City is required") : null}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group form-group-margin-bottom">
                          <label className="form-label">State* </label>
                          <select
                            className="form-control "
                            style={{ width: "40%" }}
                            name="state"
                            onChange={formik.handleChange}
                            value={formik.values.state}
                          >
                            {states.map((st) => {
                              return <option value={st}>{st} </option>;
                            })}
                          </select>
                          {formik.errors.state ? dispErrorMsg("State is required") : null}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group form-group-margin-bottom">
                          <label className="form-label">Postal code*</label>
                          <input
                            type="text"
                            placeholder="Postal code"
                            onChange={formik.handleChange}
                            value={formik.values.postal_code}
                            name="postal_code"
                            className={
                              "form-control" +
                              (formik.touched.postal_code && formik.errors.postal_code ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.postal_code && formik.errors.postal_code
                            ? dispErrorMsg("Postal code is required")
                            : null}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <div className="form-group form-group-margin-bottom">
                          <div className="form-group">
                            <label className="form-label">Country*</label>

                            <select
                              className="form-control form-dropdown"
                              style={{ width: "20%" }}
                              name="country"
                              onChange={formik.handleChange}
                              value={formik.values.country}
                            >
                              <option value="USA">USA</option>
                            </select>
                            {formik.errors.country ? dispErrorMsg("Country is required") : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-center">
                    <button type="submit" className="btn ripple  btn-primary add-user-form-submit-btn">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
