import { useParams } from "react-router-dom";
import TestimonalServices from "../../services/TestimonialService";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";

let testimonialId;

function ModifyTestimonial() {
  const { id } = useParams();
  const navigate = useNavigate();
  testimonialId = id;
  const testimonialServe = new TestimonalServices();
  const [testimonialData, setTestimonialData] = useState({});
  const [contentLoaded, setContentLoaded] = useState(false);
  useEffect(async () => {
    if (testimonialId) {
      let testimonialid = parseInt(testimonialId);
      let data = await getDetails(testimonialid);
      setTestimonialData((prev) => data);
    } else {
      var initialValues = {
        name: "",
        designation: "",
        message: "",
        image: "",
        ratings: "",
      };
      setTestimonialData((prev) => initialValues);
      setContentLoaded(true);
    }
  }, []);

  async function getDetails(Id) {
    const details = await testimonialServe.getTestimonialInfo(Id);
    console.log(details);
    setContentLoaded(true);
    return details;
  }

  const onSubmit = async (values) => {
    let obj = { ...values };
    let formObj = new FormData();
    Object.keys(obj).forEach((k) => {
      if (k == "image") {
        if (obj[k][0].name) {
          formObj.append(k, obj[k][0]);
        } else {
          formObj.append(k, obj[k]);
        }
      } else {
        formObj.append(k, obj[k]);
      }
    });

    if (testimonialId) {
      //formObj.append("id",testimonialId);
      const result = await testimonialServe.editTestimonial(formObj);
      navigate("/testimonial/list");
    } else {
      const result = await testimonialServe.addTestimonial(formObj);
      navigate("/testimonial/list");
    }
  };
  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };
  const ValidateSchema = Yup.object({
    name: Yup.string().required("Required"),
    designation: Yup.string().required("Required"),
    message: Yup.string().required("Required"),
    image: Yup.string().required("Required"),
    ratings: Yup.string().required("Required"),
  });
  const [rating, setRating] = useState(0);

  // Catch Rating value
  const handleRating = (rate) => {
    formik.values.ratings = rate;
    setRating(rate);

    // other logic
  };
  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => console.log(value, index);

  const formik = useFormik({
    initialValues: testimonialData,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <div>
      {!contentLoaded ? (
        <div className="loadingImg">
          <img src="/assets/images/loading.gif" className="text-center" />
        </div>
      ) : (
      <section className="sptb">
        <div className="container">
          <div className="row d-flex justify-content-center" style={{ marginTop: 20, marginBottom: 20 }}>
            <div className="col-xl-10 col-lg-12 col-md-12">
              <form onSubmit={formik.handleSubmit}>
                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">{testimonialId ? "Edit" : "New"} Testimonial </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group form-group-margin-bottom">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            placeholder="Name"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            name="name"
                            className={
                              "form-control" + (formik.touched.name && formik.errors.name ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.name && formik.errors.name ? dispErrorMsg("First Name is required") : null}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group form-group-margin-bottom">
                          <label className="form-label">Designation</label>
                          <input
                            type="text"
                            placeholder="Designation"
                            onChange={formik.handleChange}
                            value={formik.values.designation}
                            name="designation"
                            className={
                              "form-control" +
                              (formik.touched.designation && formik.errors.designation ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.designation && formik.errors.designation
                            ? dispErrorMsg("Designation is required")
                            : null}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group form-group-margin-bottom">
                          <label className="form-label">Message</label>
                          <input
                            type="text"
                            placeholder="Message"
                            onChange={formik.handleChange}
                            value={formik.values.message}
                            name="message"
                            className={
                              "form-control" + (formik.touched.message && formik.errors.message ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.message && formik.errors.message ? dispErrorMsg("Message is required") : null}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group form-group-margin-bottom">
                          <label className="form-label">Image</label>
                          <input
                            type="file"
                            name="image"
                            multiple
                            className={
                              "form-control" + (formik.touched.image && formik.errors.image ? " is-invalid" : "")
                            }
                            onChange={(e) => {
                              formik.setFieldValue("image", e.target.files);
                            }}
                            // onBlur={(e) => {
                            //   formik.setFieldValue("image", e.target.files);
                            // }}
                          />
                          {formik.values.image && formik.values.image.length > 0 ? (
                            Object.values(formik.values.image).map((im, ind) => {
                              if (typeof im != "string") {
                                return (
                                  <img
                                    src={URL.createObjectURL(im)}
                                    className="imgPreview"
                                    onClick={(e) => {
                                      window.open(e.target.src, "_blank");
                                    }}
                                  />
                                );
                              } else {
                                if (ind == 0) {
                                  return (
                                    <img
                                      src={formik.values.image}
                                      className="imgPreview"
                                      onClick={(e) => {
                                        window.open(e.target.src, "_blank");
                                      }}
                                    />
                                  );
                                }
                              }
                            })
                          ) : (
                            <></>
                          )}
                          {formik.touched.image && formik.errors.image ? dispErrorMsg("Image is required") : null}
                        </div>
                      </div>

                      <div className="col-sm-6 col-md-6">
                        <div className="form-group form-group-margin-bottom">
                          <label className="form-label">Ratings</label>
                          <div className="App">
                            <Rating
                              onClick={handleRating}
                              value="3"
                              handleChange={handleRating}
                              onPointerEnter={onPointerEnter}
                              onPointerLeave={onPointerLeave}
                              onPointerMove={onPointerMove}
                              /* Available Props */
                            />
                          </div>
                          {/* <input
                            type="number"
                            min="1"
                            max="100"
                            placeholder="Ratings"
                            onChange={formik.handleChange}
                            value={formik.values.ratings}
                            name="ratings"
                            className={
                              "form-control" +
                              (formik.touched.ratings && formik.errors.ratings
                                ? " is-invalid"
                                : "")
                            }
                          />
                          {formik.touched.ratings && formik.errors.ratings
                            ? dispErrorMsg("ratings is required")
                            : null} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-center">
                    <button type="submit" className="btn ripple  btn-primary add-user-form-submit-btn">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>)}
    </div>
  );
}
export default ModifyTestimonial;
