import util from "../util/util";
export default class PaymentService {
  async add(payload) {
    return util.sendApiRequest("/offer/paymentdetails", "POST", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  async deletePaymentDetail(dataId) {
    return util.sendApiRequest("/offer/paymentdetails/" + dataId, "DELETE", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  async getPaymentinfo(id) {
    return util.sendApiRequest(`/user/paymentinfo/${id}`, "GET", true, {}).then(
      (response) => {
        return response;
      },
      (response) => {
        return response;
      }
    );
  }
  async getTotalAmount(offerId) {
    return util.sendApiRequest(`/offer/payment/total/amount/${offerId}`, "GET", true, {}).then(
      (response) => {
        return response;
      },
      (response) => {
        return response;
      }
    );
  }
  async getPaymentHistory() {
    return util.sendApiRequest("/offer/payment/history/all", "GET", true, {}).then(
      (response) => {
        return response;
      },
      (response) => {
        return response;
      }
    );
  }
  async getPaymentMethodInfo(methodId) {
    return util.sendApiRequest("/user/paymentmethod/" + methodId, "GET", true, {}).then(
      (response) => {
        return response;
      },
      (response) => {
        return response;
      }
    );
  }
}
