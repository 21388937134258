import React, { useEffect, useState, useMemo } from "react";
import { useFormik, Form, ErrorMessage, FieldArray, Formik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import OfferService from "../../services/OfferService";
import UserService from "../../services/UserService";
import MessageService from "../../services/MessageService";
import PaymentService from "../../services/PaymentService";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment-timezone";
import download from "downloadjs";
moment.tz.setDefault(process.env.REACT_APP_MOMENT_TIME_ZONE);
const service = new OfferService();
const userServ = new UserService();
const messageServ = new MessageService();
const paymentServ = new PaymentService();
const ValidateSchema = Yup.object({
  user_id: Yup.string().required("User Id is required"),
  payment_status: Yup.string().required("Payment Status is required"),
  offer_status: Yup.string().required("Offer Status is required"),
  offers_products: Yup.array().of(
    Yup.object().shape(
      {
        product_name: Yup.string().required("Required"),
        upc: Yup.number()
          .integer()
          .test((digits) => new String(digits).length <= 14)
          .required("required"),
        quantity: Yup.number().integer("shouldOnlyNumber").required("Required"),
        price: Yup.number().test(
          "maxDigitsAfterDecimal",
          "number field must have 2 digits after decimal or less",
          (number) => Number.isInteger(number * 10 ** 2)
        ),
      },
      "offers products in not valid"
    )
  ),
  payment_method_id: Yup.number()
    .integer()
    .when("offer_status", {
      is: (val) => val !== "draft",
      then: Yup.number().integer().required("Payment Method is required"),
    }),
});
const offerStatus = [
  { value: "", label: "Select offer status" },
  { value: "revised", label: "Revised" },
  { value: "closed", label: "Closed" },
  { value: "rejected", label: "Rejected" },
  { value: "approved", label: "Approved" },
  { value: "not-approved", label: "Not approved" },
  { value: "shipped", label: "Shipped" },
  { value: "delivered", label: "Delivered" },
  { value: "draft", label: "Draft" },
  { value: "sent", label: "Sent" },
  { value: "cancelled", label: "Cancelled" },
  { value: "delivered-with-defects", label: "Delivered With Defects" },
];
const paymentStatus = [
  { value: "", label: "Select payment status" },
  { value: "paid", label: "Paid" },
  { value: "unpaid", label: "UnPaid" },
];
export default function AddOffers() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  useEffect(() => {
    getUserListApi();
  }, []);
  const [btnname, setBtnName] = useState(null);
  const [offerHistory, setOfferHistory] = useState([]);
  const [shippingDetails, setShippingDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [messageList, setMessageList] = useState([]);
  const [totalValues, setTotalValues] = useState(0);
  const [showMsgHis, setShowMsgHis] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [historyLength, setHistoryLength] = useState({
    activityLength: 0,
    messageLength: 0,
  });
  const [showActHis, setShowActHis] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [otherExpense, setOtherExpenses] = useState({
    main: false,
  });
  const [offerDetails, setOfferDetails] = useState({
    user_id: "",
    customerRemarks: "",
    images: [],
    images_after_delivery: [],
    new: "1",
    payment_method_id: "",
    offer_status: "",
    payment_status: "",
    expense_field: "",
    expense_value: "",
    remarks: "",
    tracking_num: "",
    tracking_url: "",
    shipment_company: "",
    offers_products: [
      {
        product_name: "",
        brand: "",
        upc: "",
        model_num: "",
        quantity: "",
        price: "",
        description: "",
      },
    ],
  });
  const [OfferMessages, SetOfferMessages] = useState([]);
  useEffect(() => {
    if (id) {
      fetchOfferDetails(id);
    } else {
      setContentLoaded(true);
    }
  }, []);

  const fetchPaymentMethod = async (userid) => {
    const result = await paymentServ.getPaymentinfo(userid);
    setPaymentDetails((prev) => result.data);
  };
  const fetchOfferDetails = async (id) => {
    try {
      const response = await service.getOfferInfo(id);
      const shipping = await service.getShipping();
      setShippingDetails((prev) => shipping.data[0]);
      let offfeerHistory = await service.offerHistory({
        id,
        sortBy: { createdAt: "desc" },
      });
      // const OfferMessages = await service.Messageslist({id,sortBy: {"send_date": "desc" ,"send_time" : "desc"}});
      const result = await messageServ.list({
        id,
        sortBy: { send_date: "desc", send_time: "desc" },
      });
      const userId = response?.offer?.user_id;
      if (response && id) {
        const userData = await userServ.getUser(userId);
        setUserDetails((prev) => userData);
        setContentLoaded(true);
      }
      if (response) {
        if (response.offer.images) {
          response.offer.images = response.offer.images.split(",");
        } else {
          response.offer.images = [];
        }
        if (response.offer.images_after_delivery) {
          response.offer.images_after_delivery = response.offer.images_after_delivery.split(",");
        } else {
          response.offer.images_after_delivery = [];
        }
        setOfferDetails((prev) => response.offer);
        SetOfferMessages((prev) => OfferMessages);
      }

      if (typeof offfeerHistory != undefined && offfeerHistory.data.length > 0) {
        for (let i = 0; i < offfeerHistory.data.length; i++) {
          offfeerHistory.data[i].createdAt = moment(offfeerHistory.data[i].createdAt).format("MM-DD-YYYY hh:mm:ss");
        }
        setOfferHistory((prev) => offfeerHistory.data);
      }
      if (result) {
        setMessageList((prev) => result.data);
      }
      setHistoryLength((prev) => {
        return {
          ...prev,
          messageLength: result.data.length,
          activityLength: offfeerHistory.data.length,
        };
      });
    } catch (err) {}
  };

  const onSubmit = async (values) => {
    let obj = { ...values };
    let formObj = new FormData();
    Object.keys(obj).forEach((k) => {
      if (k == "offers_products") {
        formObj.append("offers_products", JSON.stringify(obj.offers_products));
      } else if (k == "images") {
        for (let x = 0; x < obj.images.length; x++) {
          if (typeof obj.images[x] != "string") {
            formObj.append("images", obj.images[x]);
          } else {
            formObj.append("images", obj.images[x]);
          }
        }
      } else if (k == "images_after_delivery") {
        for (let x = 0; x < obj.images_after_delivery.length; x++) {
          if (typeof obj.images_after_delivery[x] != "string") {
            formObj.append("images_after_delivery", obj.images_after_delivery[x]);
          } else {
            formObj.append("images_after_delivery", obj.images_after_delivery[x]);
          }
        }
      } else if (k !== "total_price") {
        formObj.append(k, obj[k]);
      }
    });

    let response = null;
    formObj.append("total_price", totalValues);
    // formObj.append("status",1);
    if (obj.id) {
      response = await service.edit(formObj);
    } else {
      response = await service.add(formObj);
    }
    if (response.err) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setTimeout(() => {
        navigate("/offerlist");
      }, 1000);
    }
  };

  function handleUsers(ev) {
    getUserListApi();
  }

  async function getUserListApi() {
    let activity = {
      filter: {
        searchText: "",
        searchId: "",
      },
      sortBy: { id: "desc" },
    };
    try {
      let response = await userServ.userListApi(activity);
      if (response) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].createdAt = moment(response.data[i].createdAt).format("YYYY-MM-DD");
        }
        setUserList(response.data);
      } else {
        setUserList([]);
      }
    } catch (err) {
      throw err;
    }
  }

  const addProduct = () => {
    let products = [...formik.values.offers_products];
    products.push({
      product_name: "",
      brand: "",
      upc: "",
      model_num: "",
      quantity: "",
      price: "",
      description: "",
    });
    // formik.setValues({ offers_products: products });
    formik.setValues({ ...formik.values, offers_products: products });
  };
  const removeProducts = (index) => {
    const rows = [...formik.values.offers_products];
    rows.splice(index, 1);
    // formik.setValues({ offers_products: rows });
    formik.setValues({ ...formik.values, offers_products: rows });
  };
  const formik = useFormik({
    initialValues: offerDetails,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    fetchPaymentMethod(formik.values.user_id);
  }, [formik.values.user_id]);
  const downloadHandler = async (filePath) => {
    try {
      //download(filePath);
      // open url in new tab
      window.open(filePath, "_blank");
    } catch (err) {
      return false;
    }
  };
  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };
  useEffect(() => {
    if (formik.values.offers_products.length == 0) {
      let expVal = Number(formik.values.expense_value);
      let totalval = 0;
      if (!isNaN(expVal)) {
        totalval += expVal;
      }
      setTotalValues((prev) => totalval.toFixed(2));
    } else {
      let TotalOfferValues = 0;
      formik.values.offers_products.forEach((data) => {
        if (data.price == "" && data.quantity == "") {
          TotalOfferValues += 0;
        } else {
          TotalOfferValues += Number(data.price) * Number(data.quantity);
        }
      });
      let expVal = Number(formik.values.expense_value);
      if (!isNaN(expVal)) {
        TotalOfferValues += expVal;
      }
      setTotalValues((prev) => TotalOfferValues.toFixed(2));
    }

    return 0;
  }, [formik.values]);

  return (
    <div className="page-wrapper">
      {!contentLoaded ? (
        <div className="loadingImg">
          <img src="/assets/images/loading.gif" className="text-center" />
        </div>
      ) : (
        <>
          <section>
            <ToastContainer />
          </section>
          <section className="sptb">
            <div className="container">
              <div className="page-header-wrapper row">
                <h5 className="col-1 p-0 my-auto">
                  <u>#INV-{id}</u>
                </h5>
                <h3 className=" col-10 text-center "> Add/Edit Offer </h3>
                <button
                  className="btn btn-secondary remove-items-btn col-1 payment-btn-height ms-4 mt-0"
                  onClick={() => navigate("/offerlist")}
                >
                  Back
                </button>
              </div>
              <div className="d-flex justify-content-center me-3">
                <p className="border bg-success bg-opacity-10 border-opacity-10 fs-5 fw-bold p-2 me-5">
                  We kindly request that all products must be brand new, factory sealed and authentic condition
                </p>
              </div>
              {id && (
                <>
                  <div className="row ">
                    <div className="col-lg-6 ">
                      <p className="h6">Client Info</p>
                      <address>
                        {userDetails?.first_name} {userDetails?.last_name}
                        <br />
                        {userDetails?.address_1}
                        <br />
                        {userDetails?.state}, {userDetails?.city}
                        <br />
                        {userDetails?.email}
                      </address>
                    </div>
                    <div className="col-lg-6 text-end">
                      <p className="h6">To</p>
                      <address>
                        {shippingDetails?.first_name} {shippingDetails?.last_name}
                        <br />
                        {shippingDetails?.street_address}
                        <br />
                        {shippingDetails?.state}, {shippingDetails?.city}
                        <br />
                        {shippingDetails?.postal_code}
                        <br />
                        {shippingDetails?.email}
                      </address>
                    </div>
                  </div>
                  <div className="text-dark">
                    <p className="mb-3 mt-5 mx-3">
                      <span className="font-weight-bold">Offer Date :</span>{" "}
                      {moment(offerDetails?.createdAt?.split("T")[0]).format("MM-DD-YYYY")}
                    </p>
                  </div>
                </>
              )}
              <div className="row d-flex justify-content-center" style={{ marginTop: 20, marginBottom: 20 }}>
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <Formik>
                    <form initialValues={offerDetails} onSubmit={formik.handleSubmit}>
                      <div style={{ margin: "1rem 0rem 0rem 0rem" }}>
                        {formik.values.offers_products.map((data, index) => (
                          <div key={data}>
                            <div className="row" key={index}>
                              <div className="col-sm-6 col-md-4 ">
                                <div className="form-group">
                                  <label className="form-label fw-bold">Product name*</label>
                                  <input
                                    className={"form-control"}
                                    type="text"
                                    placeholder="Product name"
                                    value={formik.values.offers_products[index].product_name}
                                    name={`offers_products[${index}].product_name`}
                                    onChange={formik.handleChange}
                                  />
                                  {formik.touched.offers_products &&
                                  formik.touched.offers_products[index].product_name &&
                                  formik.errors.offers_products &&
                                  formik.errors?.offers_products[index]?.product_name
                                    ? dispErrorMsg("Required")
                                    : null}
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-1 ">
                                <div className="form-group">
                                  <label className="form-label fw-bold">Brand</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Brand"
                                    value={formik.values.offers_products[index].brand}
                                    name={`offers_products[${index}].brand`}
                                    onChange={formik.handleChange}
                                  />
                                </div>
                              </div>

                              <div className="col-sm-6 col-md-1 ">
                                <div className="form-group ">
                                  <label className="form-label fw-bold">Model</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Model num"
                                    value={formik.values.offers_products[index].model_num}
                                    name={`offers_products[${index}].model_num`}
                                    onChange={formik.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-2 ">
                                <div className="form-group">
                                  <label className="form-label fw-bold">UPC*</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="UPC"
                                    value={formik.values.offers_products[index].upc}
                                    name={`offers_products[${index}].upc`}
                                    onChange={formik.handleChange}
                                  />
                                </div>
                                {formik.touched.offers_products &&
                                formik.touched.offers_products[index].upc &&
                                formik.errors.offers_products &&
                                formik.errors?.offers_products[index]?.upc
                                  ? dispErrorMsg("Enter digits with max length of 14")
                                  : null}
                              </div>
                              <div className="col-sm-6 col-md-1 ">
                                <div className="form-group">
                                  <label className="form-label fw-bold">Quantity*</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Quantity"
                                    value={formik.values.offers_products[index].quantity}
                                    name={`offers_products[${index}].quantity`}
                                    onChange={formik.handleChange}
                                  />
                                  {formik.touched.offers_products &&
                                  formik.touched.offers_products[index].quantity &&
                                  formik.touched.offers_products &&
                                  formik.errors.offers_products &&
                                  formik.errors?.offers_products[index]?.quantity
                                    ? dispErrorMsg("Required and enter only number")
                                    : null}
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-1 ">
                                <div className="form-group">
                                  <label className="form-label fw-bold">Price*</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Price"
                                    value={formik.values.offers_products[index].price}
                                    name={`offers_products[${index}].price`}
                                    onChange={formik.handleChange}
                                  />
                                  {formik.touched.offers_products &&
                                  formik.touched.offers_products[index].price &&
                                  formik.touched.offers_products &&
                                  formik.errors.offers_products &&
                                  formik.errors?.offers_products[index]?.price
                                    ? dispErrorMsg("Price must have 2 digits after decimal or less")
                                    : null}
                                </div>
                              </div>
                              <div className="col-md-1">
                                <div className="form-group">
                                  <label className="form-label fw-bold">Sub total</label>
                                  <h6 className="text-center mt-1">
                                    {(
                                      formik.values.offers_products[index].price *
                                      formik.values.offers_products[index].quantity
                                    ).toFixed(2)}
                                  </h6>
                                </div>
                              </div>
                              <div className="col pt-4 col-md-1 ">
                                <button
                                  type="button"
                                  className="btn btn-secondary remove-items-btn"
                                  onClick={() => removeProducts(index)}
                                >
                                  X
                                </button>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12 col-md-12">
                                <div className="form-group form-group-margin-bottom">
                                  <label className="form-label fw-bold">Description</label>
                                  <textarea
                                    type="text"
                                    placeholder="Details"
                                    name={`offers_products[${index}].description`}
                                    onChange={formik.handleChange}
                                    value={formik.values.offers_products[index].description}
                                    className={"form-control"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="mb-2 ms-3">
                        <button
                          type="button"
                          className="btn btn-secondary add-more-btn"
                          onClick={() => {
                            addProduct();
                          }}
                        >
                          Add product
                        </button>
                      </div>

                      <div className="row ">
                        <div className="col-sm-12 col-md-12">
                          <div className="form-group form-group-margin-bottom">
                            <label className="form-label fw-bold">Customer remarks</label>
                            <textarea
                              type="text"
                              placeholder="Details"
                              name="customerRemarks"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.customerRemarks}
                              className={
                                "form-control" +
                                (formik.touched.customerRemarks && formik.errors.customerRemarks ? " is-invalid" : "")
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-12 ">
                          <div className="form-group">
                            <label className="form-label fw-bold">Admin remarks</label>
                            <textarea
                              type="text"
                              name="remarks"
                              className="form-control"
                              placeholder="Add remarks"
                              onChange={formik.handleChange}
                              value={formik.values.remarks}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row my-3 ">
                        <div className="col-sm-6 col-md-3 ">
                          <div className="form-group">
                            <label className="form-label fw-bold">Offer status*</label>
                            <select
                              className="form-control"
                              // style={{ width: "40%" }}
                              name="offer_status"
                              onChange={formik.handleChange}
                              value={formik.values.offer_status}
                            >
                              {offerStatus.map((st) => {
                                return <option value={st.value}>{st.label} </option>;
                              })}
                            </select>
                            {formik.touched.offer_status && formik.errors.offer_status
                              ? dispErrorMsg("Offer status is required")
                              : null}
                          </div>
                        </div>

                        <div className="col-sm-6 col-md-3 ">
                          <div className="form-group">
                            <label className="form-label fw-bold">Payment status*</label>
                            <select
                              className="form-control"
                              // style={{ width: "40%" }}
                              name="payment_status"
                              onChange={formik.handleChange}
                              value={formik.values.payment_status}
                            >
                              {paymentStatus.map((ps) => {
                                return <option value={ps.value}>{ps.label}</option>;
                              })}
                            </select>
                            {formik.touched.payment_status && formik.errors.payment_status
                              ? dispErrorMsg("Payment status is required")
                              : null}
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 ">
                          <div className="form-group">
                            <label className="form-label fw-bold">Payment method* </label>
                            <select
                              className="form-control"
                              // style={{ width: "40%" }}
                              name="payment_method_id"
                              onChange={formik.handleChange}
                              value={formik.values.payment_method_id}
                              onBlur={formik.handleBlur}
                            >
                              {/*  */}
                              <option value="">Select payment method</option>
                              {Array.isArray(paymentDetails) && paymentDetails.length > 0 ? (
                                paymentDetails.map((pD, index) => {
                                  return (
                                    <option value={pD.id}>
                                      {pD.platform}({pD.details})
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Select Payout Method</option>
                              )}
                            </select>
                            {formik.touched.payment_method_id && formik.errors.payment_method_id
                              ? dispErrorMsg("Payment Method is required")
                              : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-12 ">
                          <div className="form-group form-group-margin-bottom ">
                            <label className="form-label fw-bold">Images</label>
                            {formik.values.images && formik.values.images.length > 0 ? (
                              Object.values(formik.values.images).map((im, index) => {
                                if (typeof im != "string") {
                                  return (
                                    <>
                                      <img
                                        src={URL.createObjectURL(im)}
                                        className="imgPreview"
                                        onClick={(e) => {
                                          downloadHandler(e.target.src);
                                        }}
                                      />
                                      <i
                                        onClick={(e) => {
                                          let text = "want to remove image";
                                          if (confirm(text) == true) {
                                            formik.values.images = formik.values.images.filter(
                                              (obj) => obj !== formik.values.images[index]
                                            ); // create a new array without the object with id = 2
                                            let array = [...formik.values.images];
                                            formik.setFieldValue("images", array);
                                          } else {
                                          }
                                        }}
                                        className="fa fa-sharp fa-solid fa-trash img_dl_icon"
                                      ></i>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <img
                                        src={im}
                                        className="imgPreview "
                                        onClick={(e) => {
                                          downloadHandler(e.target.src);
                                        }}
                                      />
                                      <i
                                        onClick={(e) => {
                                          let text = "want to remove image";
                                          if (confirm(text) == true) {
                                            formik.values.images = formik.values.images.filter(
                                              (obj) => obj !== formik.values.images[index]
                                            ); // create a new array without the object with id = 2
                                            let array = [...formik.values.images];
                                            formik.setFieldValue("images", array);
                                          } else {
                                          }
                                        }}
                                        className="fa fa-sharp fa-solid fa-trash img_dl_icon"
                                      ></i>
                                    </>
                                  );
                                }
                              })
                            ) : (
                              <></>
                            )}
                            <input
                              type="file"
                              name="images"
                              multiple
                              className={
                                "form-control" + (formik.touched.images && formik.errors.images ? " is-invalid" : "")
                              }
                              onChange={(e) => {
                                let array1 = [...formik.values.images];
                                let array2 = e.target.files;
                                let mergedArray = [...array1, ...array2];
                                formik.setFieldValue("images", mergedArray);
                              }}
                              // onBlur={(e) => {
                              //   formik.setFieldValue("images", e.target.files);
                              // }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-md-12 ">
                          <div className="form-group form-group-margin-bottom ">
                            <label className="form-label fw-bold">Images After Delivery</label>
                            {formik.values.images_after_delivery && formik.values.images_after_delivery.length > 0 ? (
                              Object.values(formik.values.images_after_delivery).map((im, index) => {
                                if (typeof im != "string") {
                                  return (
                                    <>
                                      <img
                                        src={URL.createObjectURL(im)}
                                        className="imgPreview"
                                        onClick={(e) => {
                                          downloadHandler(e.target.src);
                                        }}
                                      />
                                      <i
                                        onClick={(e) => {
                                          let text = "want to remove image";
                                          if (confirm(text) == true) {
                                            formik.values.images_after_delivery =
                                              formik.values.images_after_delivery.filter(
                                                (obj) => obj !== formik.values.images_after_delivery[index]
                                              ); // create a new array without the object with id = 2
                                            let array = [...formik.values.images_after_delivery];
                                            formik.setFieldValue("images_after_delivery", array);
                                          } else {
                                          }
                                        }}
                                        className="fa fa-sharp fa-solid fa-trash img_dl_icon"
                                      ></i>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <img
                                        src={im}
                                        className="imgPreview"
                                        onClick={(e) => {
                                          downloadHandler(e.target.src);
                                        }}
                                      />
                                      <i
                                        onClick={(e) => {
                                          let text = "want to remove image";
                                          if (confirm(text) == true) {
                                            formik.values.images_after_delivery =
                                              formik.values.images_after_delivery.filter(
                                                (obj) => obj !== formik.values.images_after_delivery[index]
                                              ); // create a new array without the object with id = 2
                                            let array = [...formik.values.images_after_delivery];
                                            formik.setFieldValue("images_after_delivery", array);
                                          } else {
                                          }
                                        }}
                                        className="fa fa-sharp fa-solid fa-trash img_dl_icon"
                                      ></i>
                                    </>
                                  );
                                }
                              })
                            ) : (
                              <></>
                            )}
                            <input
                              type="file"
                              name="images_after_delivery"
                              multiple
                              className={"form-control"}
                              onChange={(e) => {
                                let array1 = [...formik.values.images_after_delivery];
                                let array2 = e.target.files;
                                let mergedArray = [...array1, ...array2];
                                formik.setFieldValue("images_after_delivery", mergedArray);
                              }}
                              // onBlur={(e) => {
                              //   formik.setFieldValue("images", e.target.files);
                              // }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row my-3 ">
                        <div className="col-sm-6 col-md-3 ">
                          <div className="form-group">
                            <label className="form-label fw-bold">Tracking number</label>
                            <input
                              className="form-control"
                              type="text"
                              // style={{ width: "40%" }}
                              placeholder="Tracking number"
                              name="tracking_num"
                              onChange={formik.handleChange}
                              value={formik.values.tracking_num}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-md-3 ">
                          <div className="form-group">
                            <label className="form-label fw-bold">Shipment Company</label>
                            <input
                              className="form-control"
                              // style={{ width: "40%" }}
                              type="text"
                              name="shipment_company"
                              placeholder="Shipment Company"
                              onChange={formik.handleChange}
                              value={formik.values.shipment_company}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 ">
                          <div className="form-group">
                            <label className="form-label fw-bold">Tracking URL </label>
                            <input
                              className="form-control"
                              // style={{ width: "40%" }}
                              type="text"
                              name="tracking_url"
                              placeholder="Tracking URL"
                              onChange={formik.handleChange}
                              value={formik.values.tracking_url}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group form-group-margin-bottom">
                            <label className="form-label fw-bold">User*</label>
                            <select
                              className="form-control"
                              // style={{ width: "40%" }}
                              onChange={(e) => {
                                formik.setFieldValue("payment_method_id", formik.values.user_id);
                                formik.handleChange(e);
                              }}
                              value={formik.values.user_id}
                              name="user_id"
                            >
                              <option value="">Select user name </option>
                              {userList.map((st) => {
                                return (
                                  <option value={st.id}>
                                    {st.first_name} {st.last_name} ({st.email})
                                  </option>
                                );
                              })}
                            </select>
                            {formik.touched.user_id && formik.errors.user_id
                              ? dispErrorMsg("User id is required")
                              : null}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex  justify-content-end p-4">
                        <div className="border border-danger rounded p-4 w-50">
                          <div
                            className="text-primary"
                            onClick={() =>
                              setOtherExpenses((prev) => {
                                return { ...prev, main: !prev.main };
                              })
                            }
                          >
                            Add other expense
                          </div>
                          <div className="">
                            {otherExpense.main && (
                              <>
                                <div className="d-flex flex-row row">
                                  <div className="form-group col-xl-7 col-lg-4  ">
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="enter field"
                                      name="expense_field"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.expense_field}
                                    />
                                  </div>
                                  <div className="form-group ms-1 col-xl-4 col-lg-4 ">
                                    <input
                                      className="form-control"
                                      name="expense_value"
                                      type="text"
                                      placeholder="enter value"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.expense_value}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <p>{formik.values?.expense_field}</p>
                            <p>{formik.values?.expense_value}</p>
                          </div>
                          <div className="d-flex justify-content-between ">
                            <p>Total amount</p>
                            <p>{totalValues}</p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex my-3 justify-content-around">
                        <button type="submit" className="btn ripple  btn-primary add-user-form-submit-btn">
                          Submit offer
                        </button>
                        <button
                          type="button"
                          className="btn ripple  btn-danger add-user-form-submit-btn w-25"
                          onClick={() => navigate("/offerlist")}
                        >
                          Cancel offer
                        </button>
                      </div>
                    </form>
                  </Formik>
                  {id && (
                    <>
                      <div className="row border my-5">
                        <div
                          className="col-12 d-flex justify-content-between  my-0 bg-secondary bg-opacity-10"
                          onClick={() => setShowActHis((prev) => !prev)}
                        >
                          <div className="fs-5">
                            Activity History ({historyLength.activityLength ? historyLength.activityLength : 0})
                          </div>
                          <div>{showActHis ? <span className="fs-5">-</span> : <span className="fs-5">+</span>}</div>
                        </div>
                        <div>
                          {showActHis && (
                            <div style={{ overflowY: "scroll", maxHeight: "200px" }} className="my-2">
                              <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="m-1">
                                  <div className="border rounded p-3">
                                    <table className="table table-borderless">
                                      <thead>
                                        <th>Change</th>
                                        <th>User</th>
                                        <th>Created At</th>
                                      </thead>
                                      <tbody>
                                        {offerHistory?.length > 0 &&
                                          offerHistory?.map((data, index) => {
                                            {
                                              offerHistory && offerHistory.length > 0;

                                              return (
                                                <tr>
                                                  <td dangerouslySetInnerHTML={{ __html: data?.log_message }}></td>
                                                  <td style={{ padding: "0px 2px" }}>
                                                    {data?.log_by_user.first_name} {data?.log_by_user.last_name} (
                                                    {data?.log_by_user.email})
                                                  </td>
                                                  <td>{data?.createdAt}</td>
                                                </tr>
                                              );
                                            }
                                          })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className="col-12  d-flex justify-content-between mb-0  bg-secondary bg-opacity-10"
                          onClick={() => setShowMsgHis((prev) => !prev)}
                        >
                          <div className="fs-5">
                            Message History ({historyLength.messageLength ? historyLength.messageLength : "0"})
                          </div>
                          <div>{showMsgHis ? <span className="fs-5">-</span> : <span className="fs-5">+</span>}</div>
                        </div>
                        <div>
                          {showMsgHis && (
                            <>
                              <div style={{ overflowY: "scroll", maxHeight: "200px" }} className="my-2">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                  <div className="m-1">
                                    <div className="border rounded p-3">
                                      <table className="table table-borderless">
                                        <thead>
                                          <th>User</th>
                                          <th>Message</th>
                                          <th>Timestamp</th>
                                        </thead>
                                        <tbody>
                                          {Array.isArray(messageList) &&
                                            messageList?.map((messages) => {
                                              return (
                                                <tr>
                                                  <td>{messages?.user}</td>
                                                  <td>{messages?.message}</td>
                                                  <td>
                                                    {moment(messages?.send_date).format("MM-DD-YYYY")}{" "}
                                                    {messages?.send_time}{" "}
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      <section></section>
    </div>
  );
}
