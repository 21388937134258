import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LinkName from "../LinkName/LinkName";
import Pagination from "react-bootstrap/Pagination";
import { ToastContainer, toast } from "react-toastify";
import BannerService from "../../services/BannerService";
export default function BannerList() {
  const BanneerServ = new BannerService();
  const [bannerInfo, setBannerInfo] = useState([]);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const fetchDataApi = async () => {
    let result = await BanneerServ.info();
    setBannerInfo((prev) => result.data);
  };
  useEffect(() => {
    fetchDataApi();
  }, [deleteRequest]);

  const deleteBanner = async (id) => {
    try {
      if (window.confirm("Are you sure you want to delete this banner?")) {
        let response = await BanneerServ.delete(id);
        if (response.message) {
          fetchDataApi();
          toast.success(response.message);
        } else {
          toast.error("Offer not deleted ");
        }
      }
    } catch (err) {
      throw err;
    }
  };
  return (
    // <div className="m-3   p-2">
    //   <div className="d-flex justify-content-between px-2">
    //     <h3 className="my-auto">Banners</h3>
    //     <Link className="btn btn-primary" to="/banner">Add More</Link>
    //   </div>
    //   <div className="row m-2">
    //     <div className="col-4 p-3 border bg-light">
    //       <img
    //         src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/c32512111875635.6009d0e35132a.png"
    //         className="img-fluid rounded height200"

    //       />
    //       <div className="d-flex justify-content-between">
    //       <p className="text-center  mb-0"><u>Title</u></p>
    //       <div><i className="fa fa-edit"></i></div>
    //       </div>
    //     </div>
    //     <div className="col-4 p-3 border bg-light">
    //       <img
    //         src="https://tse3.mm.bing.net/th?id=OIP.DHk3Lr-9dOry-0TeDe9A-QHaC7&pid=Api&P=0&h=180"
    //         className="img-fluid rounded height200"
    //       />
    //       <div className="d-flex justify-content-between">
    //       <p className="text-center  mb-0"><u>Title</u></p>
    //       <div><i className="fa fa-edit"></i></div>
    //       </div>
    //     </div>
    //     <div className="col-4 p-3 border bg-light">
    //       <img
    //         src="https://saraads.com/img/site/basic-ecommerce.jpg"
    //         className="img-fluid rounded height200"
    //       />
    //       <div className="d-flex justify-content-between">
    //       <p className="text-center  mb-0"><u>Title</u></p>
    //       <div><i className="fa fa-edit"></i></div>
    //       </div>
    //     </div>
    //     <div className="col-4 p-3 border bg-light">
    //       <img
    //         src="https://www.devitpl.com/wp-content/uploads/ecommerce-banner.jpg"
    //         className="img-fluid rounded height200"
    //       />
    //       <div className="d-flex justify-content-between">
    //       <p className="text-center  mb-0"><u>Title</u></p>
    //       <div><i className="fa fa-edit"></i></div>
    //       </div>
    //     </div>
    //     <div className="col-4 p-3 border bg-light">
    //       <img
    //         src="https://www.infowiz.co.in/wp-content/uploads/2019/12/ecommerce-banner.png"
    //         className="img-fluid rounded height200 "
    //       />
    //       <div className="d-flex justify-content-between">
    //       <p className="text-center  mb-0"><u>Title</u></p>
    //       <div><i className="fa fa-edit"></i></div>
    //       </div>
    //     </div>

    //   </div>
    // </div>
    <div className="ljSectionData w-100 clearfix ljpUserTables pb-4 p-relative" id="ljpUserTables">
      {/* {openModal && (
        <Modal
          closeModal={setOpenModal}
          data={selectedUser}
          items={testimonialList}
          modalData={setTestimonialList}
          getList={getTestimonialListApi}
        />
      )} */}
      <LinkName heading="Banner Database" image="/assets/images/icons/user.png" link="/user_table" />
      {/* {!contentLoaded ? (
                  <div className="loadingImg">
                    <img src="/assets/images/loading.gif" className="text-center" />
                  </div>
                ) : ( */}
      <div className="user_part">
        <div className="users_bottom_part">
          <div
            className="d-flex flex-wrap user_bottom_header align-items-center margintxt"
            style={{ justifyContent: "space-between" }}
          >
            <div className=" text-heading">
              <h4 className="mb-0">All Banners</h4>
            </div>
            <div>
              <Link className="btn btn-primary" to="/banner">
                Add Banner
              </Link>
            </div>
          </div>
          <div className="para"></div>
          <div className="users_table mt-3">
            <div className="current_courses_table table-responsive">
              <table className="main_table w-100">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Sub title</th>
                    {/* <th>Background image </th> */}
                    <th>Primary image </th>
                    <th className="action_icons">ACTIONS</th>
                  </tr>
                </thead>

                <tbody>
                  {bannerInfo?.map((key, i) => {
                    return (
                      <tr key={i}>
                        <td> {key?.title} </td>
                        <td>{key?.subtitle}</td>
                        {/* <td>
                            <a href={key?.background_image} target="_blank">
                            {key?.background_image}</a></td> */}
                        <td>
                          <a href={key?.primary_image} target="_blank">
                            {key?.primary_image}
                          </a>
                        </td>
                        <td>
                          <div className="updates_icon p-0">
                            <span
                              onClick={() => {
                                deleteBanner(key.id);
                              }}
                              className="delIconCustom fa-sharp me-1 fa-solid fa-trash"
                            ></span>
                            <Link to={`/banner/${key.id}`}>
                              <span className="editIconCustom fa-sharp me-1 fa-solid fa-pen-to-square"></span>
                              {/* <td className="text-primary"> {key.id} </td> */}
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="users_table_footer">{/* <Pagination size="sm">{items}</Pagination> */}</div>
        </div>
      </div>
      {/* )} */}
      <ToastContainer />
    </div>
  );
}
