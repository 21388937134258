import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import OfferService from "../../services/OfferService";
import LinkName from "../LinkName/LinkName";
import { useFormik, Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const ValidateSchema = Yup.object({
  productName: Yup.string().required("Name is required"),
});
const QuantValidateSchema = Yup.object({
  newQuant: Yup.number().required("Required"),
});
function Inventory() {
  const navigate = useNavigate();
  const offerServ = new OfferService();
  const [showRemarks, setShowRemarks] = useState({ open: false, remarks: "" });
  const [curr, setCurr] = useState({ colHead: null, directionValue: null });
  const [inventoryList, setInventoryList] = useState([]);
  const [showAction, setShowAction] = useState({ open: false, index: null });
  const [showProductName, setShowProductName] = useState({
    isOpen: false,
    upc: null,
  });
  const [showQuantity, setShowQuantity] = useState({
    isOpen: false,
    upc: null,
    oldQuant: null,
    product_name: null,
  });
  const [search, setSearch] = useState({
    start: 0,
    perPage: 10,
    filter: {
      searchTxt: "",
      searchUpcField: "",
      searchNameField: "",
    },
    sortBy: { createdAt: "desc" },
  });
  const fetchInventoryList = async () => {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchTxt: search.filter.searchTxt,
        searchUpcField: search.filter.searchUpcField,
        searchNameField: search.filter.searchNameField,
      },
      sortBy: search.sortBy,
    };
    let result = await offerServ.getInventoryList(activity);
    setInventoryList((prev) => result);
  };
  useEffect(() => {
    fetchInventoryList();
  }, [search]);
  const onSubmit = async (values) => {
    const product_name = values.productName;
    const upc = showProductName.upc;
    if (upc) {
      const result = await offerServ.updateProductName({ product_name, upc });
      fetchInventoryList();
    }
    setShowProductName((prev) => {
      return { ...prev, isOpen: false, upc: null };
    });
  };
  const onQuantSubmit = async (values) => {
    const new_quantity = values.newQuant;
    const old_quantity = values.oldQuant;
    const upc = showQuantity.upc;
    const product_name = values.product_name;
    const quantData = {
      new_quantity,
      old_quantity,
      upc,
      product_name,
    };
    if (upc) {
      const result = await offerServ.updateQuantity(quantData);
      fetchInventoryList();
    }
    setShowQuantity((prev) => {
      return { ...prev, isOpen: false, upc: null };
    });
  };
  const quantFormik = useFormik({
    initialValues: {
      oldQuant: showQuantity.oldQuant,
      newQuant: "",
      product_name: showQuantity.product_name,
    },
    validateOnBlur: true,
    onSubmit: onQuantSubmit,
    validationSchema: QuantValidateSchema,
    enableReinitialize: true,
  });
  const formik = useFormik({
    initialValues: { productName: "" },
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };
  const handleFilter = (e) => {
    if (e.target.name === "name") {
      const newSearch = { ...search };
      newSearch.filter.searchNameField = e.target.value;
      setSearch(newSearch);
    } else {
      const newSearch = { ...search };
      newSearch.filter.searchUpcField = e.target.value;
      setSearch(newSearch);
    }
  };
  const handleSort = (condition) => {
    const conditionArray = condition.split("-");
    let field = conditionArray[0];
    let direction = conditionArray[1];
    if (direction === "none") {
      direction = "asc";
    } else if (direction === "asc") {
      direction = "desc";
    } else {
      direction = "none";
    }

    const tempSearch = { ...search };
    let newSort = {};
    newSort[field] = direction;
    tempSearch.sortBy = newSort;
    tempSearch.start = 0;

    setCurr({
      colHead: field,
      directionValue: direction,
    });

    if (direction === "none") {
      tempSearch.sortBy = undefined;
      setSearch(tempSearch);
    } else {
      setSearch(tempSearch);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    fetchInventoryList();
  };
  const inventoryHistory = (upc) => {
    if (upc) {
      navigate(`/inventory/history/${upc}`);
    }
  };

  return (
    <>
      <div
        className="ljSectionData w-100 clearfix ljpUserTables pb-4 p-relative"
        id="ljpUserTables"
        onClick={() => {
          if (showAction.open) {
            setShowAction({ open: false, index: showAction.index });
          }
        }}
      >
        {showRemarks.open == true && (
          <div className="position-absolute top-50 start-50 z-10 translate-middle">
            <div className="user-modal-container add-edit-user-modal">
              <div className="d-flex justify-content-between">
                <h3 className="text-center">Remarks</h3>

                <button
                  type="button"
                  className="btn btn-secondary remove-items-btn mt-0"
                  onClick={() =>
                    setShowRemarks((prev) => {
                      return {
                        open: false,
                        remarks: "",
                      };
                    })
                  }
                >
                  X
                </button>
              </div>
              <div></div>

              <div className="mt-2 row">
                <div className="col-8">
                  <label className="form-label">Remarks</label>
                  <input className="form-control" value={showRemarks.remarks} readOnly />
                </div>
                <div className="col-4">
                  <label className="form-label">Price</label>
                  <input className="form-control" value={showRemarks.price} readOnly />
                </div>
              </div>
            </div>
          </div>
        )}
        {showProductName.isOpen && (
          <div className="position-absolute top-50 start-50 z-10 translate-middle">
            <div className="user-modal-container add-edit-user-modal">
              <div className="d-flex justify-content-between">
                <h3 className="text-center">Product Name</h3>

                <button
                  type="button"
                  className="btn btn-secondary remove-items-btn mt-0"
                  onClick={() =>
                    setShowProductName((prev) => {
                      return { ...prev, isOpen: false };
                    })
                  }
                >
                  X
                </button>
              </div>
              <Formik>
                <form onSubmit={formik.handleSubmit}>
                  <div className="mt-2">
                    <input
                      className={"form-control"}
                      type="text"
                      placeholder="Enter product name"
                      value={formik.values.productName}
                      name={`productName`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.productName && formik.errors?.productName ? dispErrorMsg("Required") : null}
                  </div>
                  <div className="col pt-4 col-md-3 ">
                    <button type="submit" className="btn btn-secondary remove-items-btn w-100">
                      Save
                    </button>
                  </div>
                </form>
              </Formik>
            </div>
          </div>
        )}
        {showQuantity.isOpen && (
          <div className="position-absolute top-50 start-50 z-10 translate-middle">
            <div className="user-modal-container add-edit-user-modal">
              <div className="d-flex justify-content-between">
                <h3 className="text-center">Change Quantity</h3>

                <button
                  type="button"
                  className="btn btn-secondary remove-items-btn mt-0"
                  onClick={() =>
                    setShowQuantity((prev) => {
                      return { ...prev, isOpen: false };
                    })
                  }
                >
                  X
                </button>
              </div>
              <Formik>
                <form onSubmit={quantFormik.handleSubmit}>
                  <div className="mt-2 row">
                    <div className="col-4">
                      <label className="form-label">Old Quantity</label>
                      <input className="form-control" value={quantFormik.values.oldQuant} />
                    </div>
                    <div className="col-8">
                      <label className="form-label">New quantity</label>
                      <input
                        className={"form-control"}
                        type="number"
                        placeholder="Enter new quantity"
                        value={quantFormik.values.newQuant}
                        name={`newQuant`}
                        onChange={quantFormik.handleChange}
                        onBlur={quantFormik.handleBlur}
                      />
                      {quantFormik.errors.newQuant && quantFormik.errors?.newQuant ? dispErrorMsg("Required") : null}
                    </div>
                  </div>
                  <div className="col pt-4 col-md-3 ">
                    <button type="submit" className="btn btn-secondary remove-items-btn w-100">
                      Save
                    </button>
                  </div>
                </form>
              </Formik>
            </div>
          </div>
        )}
        <LinkName heading="Inventory Database" image="/assets/images/icons/user.png" link="/user_table" />
        <div className="user_part">
          <div className="users_bottom_part">
            <div
              className="d-flex flex-wrap user_bottom_header align-items-center margintxt"
              style={{ justifyContent: "space-between" }}
            >
              <div className=" text-heading">
                <h4 className="mb-0">All Inventory</h4>
              </div>
            </div>
            <div className="para"></div>
            <div className="d-flex flex-row-reverse">
              <div className="mt-2">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    const newSearch = { ...search };
                    newSearch.filter.searchNameField = "";
                    newSearch.filter.searchUpcField = "";
                    setSearch(newSearch);
                  }}
                >
                  Reset
                </button>
              </div>

              <div className="col-xl-4 col-md-4 col-sm-12 mx-5 mt-3">
                <div className="input-group update_search_bar ">
                  <button className="btn" type="button"></button>
                  <input
                    type="text"
                    className="form-control border-0"
                    placeholder="Search for UPC"
                    onChange={(e) => {
                      handleFilter(e);
                    }}
                    name="upc"
                    value={search.filter.searchUpcField}
                  />
                  <div className="input-group-append">
                    <button className="btn search_btn" type="button">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-4 col-sm-12 mx-5 mt-3">
                <div className="input-group update_search_bar ">
                  <button className="btn" type="button"></button>
                  <input
                    type="text"
                    className="form-control border-0"
                    placeholder="Search for Product Name"
                    onChange={(e) => {
                      handleFilter(e);
                    }}
                    name="name"
                    value={search.filter.searchNameField}
                  />
                  <div className="input-group-append ">
                    <button className="btn search_btn" type="button">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="users_table mt-3">
              <div className="current_courses_table table-responsive">
                <table className="main_table w-100">
                  <thead>
                    <th>
                      <div className="d-flex justify-content-between">
                        <div>Product Name</div>
                        <div className="text-center ">
                          {curr?.colHead === "product_name" && curr?.directionValue === "asc" ? (
                            <i className="fa fa-caret-up" onClick={() => handleSort("product_name-asc")}></i>
                          ) : null}
                          {curr?.colHead === "product_name" && curr?.directionValue === "desc" ? (
                            <i className="fa fa-caret-down" onClick={() => handleSort("product_name-desc")}></i>
                          ) : null}
                          {curr.colHead === "product_name" && curr.directionValue === "none" ? (
                            <i className="fa fa-sort opacity-25" onClick={() => handleSort("product_name-none")}></i>
                          ) : null}
                          {curr.colHead !== "product_name" ? (
                            <i className="fa fa-sort opacity-25" onClick={() => handleSort("product_name-none")}></i>
                          ) : null}
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="d-flex justify-content-between">
                        <div>UPC</div>
                        <div className="text-center ">
                          {curr?.colHead === "upc" && curr?.directionValue === "asc" ? (
                            <i className="fa fa-caret-up" onClick={() => handleSort("upc-asc")}></i>
                          ) : null}
                          {curr?.colHead === "upc" && curr?.directionValue === "desc" ? (
                            <i className="fa fa-caret-down" onClick={() => handleSort("upc-desc")}></i>
                          ) : null}
                          {curr.colHead === "upc" && curr.directionValue === "none" ? (
                            <i className="fa fa-sort opacity-25" onClick={() => handleSort("upc-none")}></i>
                          ) : null}
                          {curr.colHead !== "upc" ? (
                            <i className="fa fa-sort opacity-25" onClick={() => handleSort("upc-none")}></i>
                          ) : null}
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="d-flex justify-content-between">
                        <div>Quantity</div>
                        <div className="text-center ">
                          {curr?.colHead === "quantity" && curr?.directionValue === "asc" ? (
                            <i className="fa fa-caret-up" onClick={() => handleSort("quantity-asc")}></i>
                          ) : null}
                          {curr?.colHead === "quantity" && curr?.directionValue === "desc" ? (
                            <i className="fa fa-caret-down" onClick={() => handleSort("quantity-desc")}></i>
                          ) : null}
                          {curr.colHead === "quantity" && curr.directionValue === "none" ? (
                            <i className="fa fa-sort opacity-25" onClick={() => handleSort("quantity-none")}></i>
                          ) : null}
                          {curr.colHead !== "quantity" ? (
                            <i className="fa fa-sort opacity-25" onClick={() => handleSort("quantity-none")}></i>
                          ) : null}
                        </div>
                      </div>
                    </th>
                    <th className="action_icons position-relative">ACTIONS</th>
                  </thead>
                  <tbody>
                    {Array.isArray(inventoryList) &&
                      inventoryList?.map((data, i) => {
                        return (
                          <>
                            <tr>
                              <td>
                                {" "}
                                <Link to={`/inventory/history/${data.upc}`}>
                                  {data.product_name ? data.product_name : "NF"}
                                </Link>
                              </td>
                              <td>{data.upc}</td>
                              <td>{data.quantity}</td>
                              <td style={{ position: "relative" }}>
                                <i
                                  className="fa fa-ellipsis-v p-2 hands-icon"
                                  aria-hidden="true"
                                  onClick={
                                    () =>
                                      setShowAction((prev) => {
                                        return { open: !prev.open, index: i };
                                      })

                                    // if (showAction.open) {
                                    //   setShowAction({ open: false, index: showAction.index });
                                    // }
                                  }
                                ></i>
                                {showAction.open && showAction.index == i && (
                                  <>
                                    <div className="border rounded z-10 position-absolute top-10  end-30">
                                      <ul className="list-group bg-white ">
                                        <li
                                          className="mx-3 my-2 mb-2 border-0 d-flex jusity-content-around hands-icon"
                                          onClick={() =>
                                            setShowProductName((prev) => {
                                              return {
                                                ...prev,
                                                isOpen: true,
                                                upc: data.upc,
                                              };
                                            })
                                          }
                                        >
                                          <span className="fa fa-pencil-square-o me-1"></span>
                                          <span className="text-primary fw-light ms-2 ">Product Name</span>
                                        </li>
                                        <li
                                          className="mx-3 my-2 mb-2 border-0 d-flex jusity-content-around hands-icon"
                                          onClick={() =>
                                            setShowQuantity((prev) => {
                                              return {
                                                ...prev,
                                                isOpen: true,
                                                upc: data.upc,
                                                oldQuant: data.quantity,
                                                product_name: data.product_name,
                                              };
                                            })
                                          }
                                        >
                                          <span className="fa fa-pencil-square-o me-1"></span>
                                          <span className="text-primary fw-light ms-2 ">Change Quantity</span>
                                        </li>
                                        <li
                                          className="mx-3 my-2 mb-2 border-0 d-flex jusity-content-around hands-icon"
                                          // onClick={() =>
                                          //   setShowQuantity((prev) => {
                                          //     return {
                                          //       ...prev,
                                          //       isOpen: true,
                                          //       upc: data.upc,
                                          //       oldQuant: data.quantity,
                                          //       product_name: data.product_name,
                                          //     };
                                          //   })
                                          // }
                                          onClick={() => {
                                            inventoryHistory(data.upc, data.quantity);
                                          }}
                                        >
                                          <span className="fa fa-pencil-square-o me-1"></span>
                                          <span className="text-primary fw-light ms-2 ">Inventory History</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </>
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Inventory;
