import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState, useMemo, useRef } from "react";
import OfferService from "../../services/OfferService";
import MessageService from "../../services/MessageService";
// import OfferService from "../../services/OfferService";
import UserService from "../../services/UserService";
import moment from "moment-timezone";
moment.tz.setDefault(process.env.REACT_APP_MOMENT_TIME_ZONE);
const userServ = new UserService();
const service = new OfferService();
const messageServ = new MessageService();
function Message() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [messageList, setMessageList] = useState([]);
  const [height, setHeight] = useState("450px");
  const [newApiCall, setnewApiCall] = useState(true);
  const [offer, setOfferDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const inputref = useRef();
  const user = useMemo(() => {
    const user = JSON.parse(window.localStorage.getItem("user"))?.result;
    let Name;
    let user_id;
    let avatar;
    if (user) {
      Name = user.first_name + " " + user.last_name;
      user_id = user.id;
      avatar = user.first_name[0] + user.last_name[0];
    }
    return { Name, user_id, avatar };
  }, []);
  //   console.log("Name from use memo hook is ", Name);
  const getMessageApi = async () => {
    try {
      const result = await messageServ.list({ id, markRead: true, sortBy: { send_date: "asc", send_time: "asc" } });
      const response = await service.getOfferInfo(id);
      const userId = response?.offer?.user_id;
      if (userId) {
        const userData = await userServ.getUser(userId);
        setUserDetails((prev) => userData);
      }
      if (result) {
        setMessageList((prev) => result.data);
      }
      if (response) {
        setOfferDetails((prev) => response.offer);
      }
    } catch (err) {
      // window.confirm(JSON.parse(err).err);
    }
  };
  function ScrollToBottom() {
    setTimeout(() => {
      let a = document.getElementById("scrollbtm");
      a.scroll(0, a.scrollHeight);
    }, 500);
  }
  useEffect(() => {
    getMessageApi();
  }, [newApiCall]);
  const sendMessage = async (e) => {
    e.preventDefault();
    const message = inputref.current.value;

    const today = JSON.stringify(new Date());
    const todayArr = today.split("T");
    const date = todayArr[0].split('"')[1];
    const time = todayArr[1].split(".")[0];
    let obj = {
      offer_id: id,
      admin_id: user.user_id,
      send_by: "admin",
      message: message,
      send_date: date,
      send_time: time,
    };
    //    window.confirm(JSON.stringify(obj));
    let result = await messageServ.send(obj);
    if (result) {
      inputref.current.value = "";
      setnewApiCall((prev) => !prev);
      ScrollToBottom();
    }
  };
  return (
    <div className="mt-3">
      <h3 className="m-2">Message</h3>
      <section className="sptb">
        <div className="container">
          <div className="card">
            <div className="card-header border">
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn btn-secondary remove-items-btn col-1 mt-0 payment-btn-height"
                  onClick={() => navigate("/offerlist")}
                >
                  Back
                </button>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="row ">
                    <div className="col-lg-12 col-md-9 col-sm-7">
                      <p className="h3">Offer Details - {offer.id}</p>
                      <div>
                        <span className="fw-bold">Customer Remarks</span> : {offer?.customerRemarks}
                        <br />
                        <span className="fw-bold">Admin Remarks</span> : {offer?.remarks}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-3 col-sm-5 mt-3">
                      <p className="h3">Client Details</p>
                      <address>
                        {userDetails?.first_name} {userDetails?.last_name}
                        <br />
                        {userDetails?.address_1}
                        <br />
                        {userDetails?.state}, {userDetails?.city}
                        <br />
                        {userDetails?.email}
                      </address>
                    </div>
                    <label className="form-label fw-bolder my-1 text-primary">Products List</label>
                    <div className="w-100">
                      <div className="users_table ">
                        <div className="current_courses_table table-responsive">
                          <table className="main_table w-100">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Brand</th>
                                <th>UPC</th>
                                <th>Quantity</th>
                                <th>Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {offer?.offers_products?.map((off, i) => {
                                return (
                                  <tr>
                                    <td>{off.product_name}</td>
                                    <td>{off.brand}</td>
                                    <td>{off.upc}</td>
                                    <td>{off.quantity}</td>
                                    <td>{off.price}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-dark">
                    <p className="mb-3 mt-5">
                      <span className="font-weight-semibold">Offer Date :</span>{" "}
                      {moment(offer?.createdAt?.split("T")[0]).format("MM-DD-YYYY")}
                    </p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card-body">
                    <div className="d-flex flex-column overflow-auto " style={{ height }} id="scrollbtm">
                      {Array.isArray(messageList) ? (
                        <>
                          {messageList?.map((messages) => {
                            return (
                              <div>
                                <div className="media ps-5 pe-5 pb-3 mt-0">
                                  <div className="d-flex me-3">
                                    {user.image ? (
                                      <img
                                        className="media-object brround"
                                        //   alt="64x64"
                                        src={user.image ? user.image : null}
                                      />
                                    ) : (
                                      <span className="rounded-circle bg-dark text-white p-2">
                                        {messages.send_by === "seller" ? "SE" : user.avatar}
                                      </span>
                                    )}
                                    <h4 className="mt-1 mb-1 ms-2">
                                      {messages.send_by === "seller" ? "seller" : user.Name}
                                    </h4>
                                  </div>
                                  <div className="media-body ms-5">
                                    <small className="text-muted">
                                      <i className="fa fa-calendar" /> {moment(messages.send_date).format("MM-DD-YYYY")}
                                      <i className=" ms-3 fa fa-clock-o" /> {messages.send_time}
                                    </small>
                                    <p className="fs-15  mb-2 mt-2">{messages.message}</p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="input-group mb-3 ">
                    <form
                      className="row w-100 ms-1 mt-2"
                      onSubmit={(e) => {
                        sendMessage(e);
                      }}
                    >
                      <input
                        type="text"
                        ref={inputref}
                        className="form-control w-75 p-4 col-9"
                        placeholder="start typing"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      ></input>
                      <button className="col-2 p-2 ms-3 btn btn-primary" type="submit">
                        send
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Message;
