import React, { useEffect, useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import UserService from "../../services/UserService";
import { useNavigate, useParams } from "react-router-dom";

const userServe = new UserService();

const states = [
  "",
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
export default function AddUser() {
  const navigate = useNavigate();
  const { id } = useParams();
  const ValidateSchema = Yup.object({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
    // password: Yup.string().required("Required"),
    address_1: Yup.string().required("Required"),
    address_2: Yup.string(),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    //country: Yup.string().required("Required"),
    // confirm_password: Yup.string().required("Required"),
    role: Yup.string().required("Required"),
  });

  const [passwordErrorMsg, setPasswordErrorMsg] = useState(false);
  const [checkPasswords, setCheckPasswords] = useState(false);
  const [checkEdit, setCheckEdit] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [addEditUser, setAddEditUser] = new useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    phone: "",
    state: "",
    country: "USA",
    city: "",
    company_name: "",
    role: "",
    address_1: "",
    address_2: "",
    confirm_password: "",
    new: "1",
    payout_preferences: ["paypal"],
  });

  useEffect(() => {
    fetchUserProfile(id);
    if (!id) {
      setContentLoaded(true);
    }
  }, []);

  const fetchUserProfile = async (id) => {
    if (id) {
      const response = await userServe.getUser(id);
      if (response) {
        setContentLoaded(true);
        delete response.password;
        if (response.payout_preferences) {
          response.payout_preferences = response.payout_preferences.split(",");
        }
        setAddEditUser(response);
        // setCheckEdit(true);
      }
    }
  };

  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };

  const onSubmit = async (values) => {
    let obj = { ...values };
    const payout_preferences = obj.payout_preferences.toString();
    obj.payout_preferences = payout_preferences;
    let check = false;
    if (obj.new) {
      if (obj.password !== "" || obj.confirm_password !== "") {
        if (obj.password === obj.confirm_password) {
          delete obj.confirm_password;
          try {
            const response = await userServe.addUser(obj);
            if (response?.result) {
              toast(response.message);
              setPasswordErrorMsg(false);
              check = true;
            } else {
              toast(response.error);
            }
          } catch (err) {
            err = JSON.parse(err.message);
          }
        } else {
          setPasswordErrorMsg(true);
          toast.warn("Passwords does not match!");
        }
      } else {
        setCheckPasswords(true);
        toast.error("Please add passwords");
      }
    } else {
      const response = await userServe.edit(obj);
      toast.success(response.message);
      check = true;
    }
    if (check == true) {
      setTimeout(() => {
        navigate("/user");
      }, 1500);
    }
  };

  const isNumericString = (str) => {
    return /^\d+$/.test(str);
  };

  const formik = useFormik({
    initialValues: addEditUser,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  const handlePayout = (e) => {
    if (e.target.checked) {
      const method = e.target.value;
      const availableMethod = formik.values.payout_preferences;
      const findMethod = availableMethod.filter((value) => {
        return value === method;
      });
      if (findMethod.length > 0) {
        return;
      } else {
        const newMethod = [...availableMethod, method];
        formik.setFieldValue("payout_preferences", newMethod);
      }
    } else {
      const method = e.target.value;
      const availableMethod = formik.values.payout_preferences;
      const newMethod = availableMethod.filter((value) => value !== method);
      formik.setFieldValue("payout_preferences", newMethod);
    }
  };
  return (
    <div>
      <section>
        <ToastContainer />
      </section>
      <section className="sptb">
        <div className="container">
          <div className="row d-flex justify-content-center" style={{ marginTop: 20, marginBottom: 20 }}>
            <div className="col-xl-10 col-lg-12 col-md-12">
              {!contentLoaded ? (
                <div className="loadingImg">
                  <img src="/assets/images/loading.gif" className="text-center" />
                </div>
              ) : (
                <form onSubmit={formik.handleSubmit}>
                  <div className="card mb-0">
                    <div className="card-header d-flex justify-content-between">
                      <h3 className="card-title">{id ? "Edit" : "Add"} User </h3>
                      <button
                        className="btn btn-secondary remove-items-btn col-1 payment-btn-height ms-4 mt-0 border "
                        onClick={() => navigate("/user")}
                      >
                        Back
                      </button>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group form-group-margin-bottom">
                            <label className="form-label">First name*</label>
                            <input
                              type="text"
                              placeholder="First name"
                              onChange={formik.handleChange}
                              value={formik.values.first_name}
                              name="first_name"
                              className={
                                "form-control" +
                                (formik.touched.first_name && formik.errors.first_name ? " is-invalid" : "")
                              }
                            />
                            {formik.touched.first_name && formik.errors.first_name
                              ? dispErrorMsg("First name is required")
                              : null}
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group form-group-margin-bottom">
                            <label className="form-label">Last name*</label>
                            <input
                              type="text"
                              placeholder="Last name"
                              onChange={formik.handleChange}
                              value={formik.values.last_name}
                              name="last_name"
                              className={
                                "form-control" +
                                (formik.touched.last_name && formik.errors.last_name ? " is-invalid" : "")
                              }
                            />
                            {formik.touched.last_name && formik.errors.last_name
                              ? dispErrorMsg("Last name is required")
                              : null}
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group form-group-margin-bottom">
                            <label className="form-label">Email address*</label>
                            <input
                              type="email"
                              placeholder="Email"
                              onChange={formik.handleChange}
                              value={formik.values.email}
                              name="email"
                              className={
                                "form-control" + (formik.touched.email && formik.errors.email ? " is-invalid" : "")
                              }
                            />
                            {formik.touched.email && formik.errors.email ? dispErrorMsg("Email is required") : null}
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group form-group-margin-bottom">
                            <label className="form-label">Phone number</label>
                            <input
                              type="text"
                              placeholder="Number"
                              onChange={formik.handleChange}
                              value={formik.values.phone}
                              name="phone"
                              className={
                                "form-control" + (formik.touched.phone && formik.errors.phone ? " is-invalid" : "")
                              }
                            />
                            {formik.touched.phone && formik.errors.phone
                              ? dispErrorMsg("Phone number is required")
                              : null}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-group-margin-bottom">
                            <label className="form-label">Address 1</label>
                            <input
                              type="text"
                              placeholder="Address"
                              onChange={formik.handleChange}
                              value={formik.values.address_1}
                              name="address_1"
                              className={
                                "form-control" +
                                (formik.touched.address_1 && formik.errors.address_1 ? " is-invalid" : "")
                              }
                            />
                            {formik.touched.address_1 && formik.errors.address_1
                              ? dispErrorMsg("Address is required")
                              : null}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-group-margin-bottom">
                            <label className="form-label">Address 2</label>
                            <input
                              type="text"
                              placeholder="Address"
                              onChange={formik.handleChange}
                              value={formik.values.address_2}
                              name="address_2"
                              className={
                                "form-control" +
                                (formik.touched.address_2 && formik.errors.address_2 ? " is-invalid" : "")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group form-group-margin-bottom">
                            <label className="form-label">City</label>
                            <input
                              type="text"
                              placeholder="City"
                              onChange={formik.handleChange}
                              value={formik.values.city}
                              name="city"
                              className={
                                "form-control" + (formik.touched.city && formik.errors.city ? " is-invalid" : "")
                              }
                            />
                            {formik.touched.city && formik.errors.city ? dispErrorMsg("City is required") : null}
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group form-group-margin-bottom">
                            <label className="form-label">State</label>
                            <select
                              className="form-control"
                              name="state"
                              onChange={formik.handleChange}
                              value={formik.values.state}
                            >
                              {states.map((st) => {
                                return <option value={st}>{st} </option>;
                              })}
                            </select>
                            {formik.errors.state ? dispErrorMsg("State is required") : null}
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group form-group-margin-bottom">
                            <label className="form-label">Zipcode</label>
                            <input
                              type="text"
                              placeholder="Zipcode"
                              onChange={formik.handleChange}
                              value={formik.values.zipcode}
                              name="zipcode"
                              className={
                                "form-control" + (formik.touched.zipcode && formik.errors.zipcode ? " is-invalid" : "")
                              }
                            />
                            {formik.touched.zipcode && formik.errors.zipcode
                              ? dispErrorMsg("Zipcode is required")
                              : null}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="form-group form-group-margin-bottom">
                            <div className="form-group">
                              <label className="form-label">Country*</label>

                              <select
                                className="form-control form-dropdown"
                                style={{ width: "20%" }}
                                name="country"
                                onChange={formik.handleChange}
                                value={formik.values.country}
                              >
                                <option value="USA">USA</option>
                              </select>
                              {formik.errors.country ? dispErrorMsg("Country is required") : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group form-group-margin-bottom">
                            <label className="form-label">Company name (optional)</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Company"
                              onChange={formik.handleChange}
                              value={formik.values.company_name}
                              name="company_name"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group form-group-margin-bottom">
                            <label className="form-label">Password {id ? "(Optional)" : ""}</label>
                            <input
                              type="password"
                              placeholder={id ? "password(optional)" : "password"}
                              onChange={formik.handleChange}
                              value={formik.values.password}
                              name="password"
                              className={"form-control" + (checkPasswords ? " is-invalid" : "")}
                            />
                            {/* {formik.touched.password && formik.errors.password
           ? dispErrorMsg("Password is required")
           : null} */}
                            {checkPasswords ? dispErrorMsg("Password is required") : null}
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group form-group-margin-bottom">
                            <label className="form-label">Confirm password {id ? "(Optional)" : ""}</label>
                            <input
                              type="password"
                              placeholder="password"
                              onChange={formik.handleChange}
                              value={formik.values.confirm_password}
                              name="confirm_password"
                              className={"form-control" + (checkPasswords ? " is-invalid" : "")}
                            />
                            {checkPasswords ? dispErrorMsg("Confirm password is required") : null}
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group form-group-margin-bottom">
                            <label className="form-label">Role* </label>
                            <select
                              name="role"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.role}
                              className={
                                "form-control" + (formik.touched.role && formik.errors.role ? " is-invalid" : "")
                              }
                            >
                              <option> Select </option>
                              <option value="1" key="admin">
                                Admin
                              </option>
                              <option value="2" key="seller">
                                Seller
                              </option>
                            </select>
                            {formik.touched.role && formik.errors.role ? dispErrorMsg("Role is required") : null}
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group form-group-margin-bottom">
                            <label className="form-label">Payout preferences</label>
                            {/* <select
           name="payout_preferences"
           onChange={formik.handleChange}
           onBlur={formik.handleBlur}
           value={formik.values.payout_preferences}
           className={
             "form-control" +
             (formik.touched.payout_preferences && formik.errors.payout_preferences
               ? " is-invalid"
               : "")
           }
         >
           <option> Select </option>
           <option value="zelle">
             Zelle
           </option>
         </select> */}
                            <br />
                            <div className="d-flex">
                              <div className="ms-2 ">
                                <input
                                  id="zelle"
                                  name="payout_preferences"
                                  type="checkbox"
                                  value="zelle"
                                  className="mt-1"
                                  onChange={(e) => handlePayout(e)}
                                  checked={formik.values?.payout_preferences?.includes("zelle") ? true : false}
                                />
                                <label for="zelle" className="ms-1">
                                  Zelle
                                </label>
                              </div>
                              <div className="ms-2">
                                <input
                                  id="venmo"
                                  name="payout_preferences"
                                  type="checkbox"
                                  value="venmo"
                                  onChange={(e) => handlePayout(e)}
                                  checked={formik.values?.payout_preferences?.includes("venmo") ? true : false}
                                />
                                <label for="venmo" className="ms-1">
                                  Venmo
                                </label>
                              </div>
                              <div className="ms-2">
                                <input
                                  id="paypal"
                                  name="payout_preferences"
                                  type="checkbox"
                                  value="paypal"
                                  checked={formik.values?.payout_preferences?.includes("paypal") ? true : false}
                                  onChange={(e) => handlePayout(e)}
                                />
                                <label for="paypal" className="ms-1">
                                  Paypal
                                </label>
                              </div>
                              <div className="ms-2">
                                <input
                                  id="cashapp"
                                  name="payout_preferences"
                                  type="checkbox"
                                  value="cashapp"
                                  onChange={(e) => handlePayout(e)}
                                  checked={formik.values?.payout_preferences?.includes("cashapp") ? true : false}
                                />
                                <label for="cashapp" className="ms-1">
                                  CashApp
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {passwordErrorMsg && (
                          <div className="valid_feedbackMsg text-center">
                            <p className="user-auth-msg" style={{ color: "red", marginTop: 20 }}>
                              Password does not match!
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="card-footer d-flex justify-content-center">
                      <button type="submit" className="btn ripple  btn-primary add-user-form-submit-btn">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
