import React, { useEffect, useState } from "react";
import { Await, Link } from "react-router-dom";
import moment from "moment-timezone";
import PieChartService from "../../services/PieChartService";
import Highcharts from "highcharts";
import DatePicker from "react-datepicker";
import { useFormik, Form, ErrorMessage, FieldArray, Formik } from "formik";
import HighchartsReact from "highcharts-react-official";
import UserService from "../../services/UserService";
import Select from "react-select";
const pieServ = new PieChartService();
const userServ = new UserService();
let options = [
  { value: "", label: "Select user name" },
  // Add more options as needed
];
function SellerPage() {
  const [chartData, setChartData] = useState({});
  const [UserId, SelectUserId] = useState();
  const [filterDay, setFilterDay] = useState(1);
  const [userList, setUserList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const getPieChartData = async () => {
    const series = await pieServ.getData();
    const options = {
      chart: {
        type: "pie",
      },
      title: {
        text: "UPC Chart",
      },
      plotOptions: {
        series: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
      },
    };
    options.series = series;
    setChartData((prev) => options);
  };

  const handleSelect = (event) => {
    const selectedValue = event.value;
    GetUserID(selectedValue);
  };

  async function getUserListApi() {
    let activity = {
      filter: {
        searchText: "",
        searchId: "",
      },
      sortBy: { id: "desc" },
    };
    try {
      let response = await userServ.userListApi(activity);
      if (response) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].createdAt = moment(response.data[i].createdAt).format("YYYY-MM-DD");
        }
        setUserList(response.data);
        options = [];
        response.data.forEach(async (item) => {
          options.push({ value: item.id, label: item.first_name + " " + item.last_name + " " + item.email });
        });
      } else {
        setUserList([]);
      }
    } catch (err) {
      throw err;
    }
  }
  async function GetUserID(user_id) {
    SelectUserId(user_id);
  }

  useEffect(() => {
    getPieChartData();
  }, []);
  useEffect(() => {
    getUserListApi();
  }, []);
  useEffect(() => {
    getCardData();
  }, [UserId, filterDay, startDate, endDate]);
  const [cardResponse, setCardResponse] = useState("");
  const getCardData = async () => {
    try {
      if (filterDay == "custom") {
        let response = await userServ.getAdminSellerDashboardDataDateRange(
          UserId,
          startDate ? moment(startDate).tz("America/New_York").format("YYYY-MM-DD") : "2000-01-01",
          endDate ? moment(endDate).tz("America/New_York").format("YYYY-MM-DD") : "2060-12-31"
        );
        setCardResponse(response);
      } else if (filterDay == "this_year") {
        let Difference_In_Days = moment().dayOfYear();
        let response = await userServ.getAdminSellerDashboardData(UserId, Difference_In_Days);
        setCardResponse(response);
      } else {
        let response = await userServ.getAdminSellerDashboardData(UserId, filterDay);
        setCardResponse(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="clearfix mt-4" id="ljSectionData">
      <div className="d-flex justify-content-between dashboardHeading row ">
        <div className="row">
          <div className="title-container-dashbrd mb-4 col-sm-4 col-md-4">
            <img
              src="/assets/images/icons/Vector(25).png"
              alt="logo"
              className="img-fluid sidebar-img "
              style={{ color: "#4A5056" }}
              width="22"
            />
            <span
              style={{
                paddingLeft: "10px",
                marginTop: "-7px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "28px",
                color: "#4A5056",
              }}
            >
              Seller Stands
            </span>
          </div>
          <div className="col-sm-4 col-md-4">
            <div className="form-group form-group-margin-bottom">
              <Select
                options={options}
                values={options}
                placeholder="Select user name"
                onChange={handleSelect}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-4 col-md-4 d-flex justify-content-between filterInp mb-4">
            {filterDay != "custom" ? (
              <>
                <select
                  className="form-control selectInp"
                  onChange={(e) => {
                    setFilterDay(e.target.value);
                  }}
                >
                  <option value="1">Today </option>
                  <option value="7">7 Days</option>
                  <option value="30">30 Days</option>
                  <option value="60">60 Days</option>
                  <option value="90">90 Days</option>
                  <option value="this_year">This Year</option>
                  <option value="custom">Custom</option>
                </select>
                <i class="width10 fas fa-filter m-auto mx-2"></i>
              </>
            ) : (
              filterDay == "custom" && (
                <div className="row">
                  <div className="col-md-5 mt-1 mb-1">
                    <DatePicker
                      placeholderText="Start Date"
                      onChange={(date) => setStartDate(date)}
                      selected={startDate}
                      maxDate={endDate}
                    />
                  </div>
                  <div className="col-md-5 mt-1 mb-1">
                    <DatePicker
                      placeholderText="End Date"
                      onChange={(date) => setEndDate(date)}
                      selected={endDate}
                      minDate={startDate}
                    />
                  </div>
                  <div className="col-md-1 mt-2">
                    <i class="width10 fa fa-close m-auto mx-2" onClick={() => setFilterDay(1)}></i>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <div className="allCardList mt-1">
        <div className="row">
          <div className=" card-box-dashbrd col-xl-2 col-md-6 col-6 pb-6">
            <div className="FullViews card">
              <Link
                to={`/offerlist?u=${UserId}&s=closed&d=${filterDay}&sd=${startDate}&ed=${endDate}`}
                className="d-flex"
              >
                <h6 className="m-0">Closed offers Count & Amount</h6>
                <h3 className="m-0 full-amount">
                  {cardResponse?.closed_offers?.cnt ? cardResponse?.closed_offers?.cnt : 0} - $
                  {cardResponse?.closed_offers?.amount ? cardResponse?.closed_offers?.amount : 0}
                </h3>
              </Link>
            </div>
          </div>
          <div className="card-box-dashbrd col-xl-2 col-md-6 col-6 pb-4">
            <div className="FullViews card">
              <Link
                to={`/offerlist?u=${UserId}&s=approved&d=${filterDay}&sd=${startDate}&ed=${endDate}`}
                className="d-flex"
              >
                <h6 className="m-0">Approved Offers Count & Amount</h6>
                <h3 className="m-0 full-amount">
                  {cardResponse?.approved_offers?.cnt ? cardResponse?.approved_offers?.cnt : 0} - $
                  {cardResponse?.approved_offers?.amount ? cardResponse?.approved_offers?.amount : 0}
                </h3>
              </Link>
            </div>
          </div>
          <div className="col-xl-2 col-md-6 col-6 pb-4 card-box-dashbrd">
            <div className="FullViews card">
              <Link
                to={`/offerlist?u=${UserId}&s=sent&d=${filterDay}&sd=${startDate}&ed=${endDate}`}
                className="d-flex"
              >
                <h6 className="m-0 ">Sent Offers Count & Amount</h6>
                <h3 className="m-0 full-amount">
                  {cardResponse?.sent_offers?.cnt ? cardResponse?.sent_offers?.cnt : 0} - $
                  {cardResponse?.sent_offers?.amount ? cardResponse?.sent_offers?.amount : 0}
                </h3>
              </Link>
            </div>
          </div>
          <div className="col-xl-2 col-md-6 col-6 pb-4 card-box-dashbrd">
            <div className="FullViews card">
              <Link
                to={`/offerlist?u=${UserId}&s=shipped&d=${filterDay}&sd=${startDate}&ed=${endDate}`}
                className="d-flex"
              >
                <h6 className="m-0 ">Shipped Offers count & Amount</h6>
                <h3 className="m-0 full-amount">
                  {cardResponse?.shipped_offers?.cnt ? cardResponse?.shipped_offers?.cnt : 0}- $
                  {cardResponse?.shipped_offers?.amount ? cardResponse?.shipped_offers?.amount : 0}
                </h3>
              </Link>
            </div>
          </div>
          <div className="col-xl-2 col-md-6 col-6 pb-4 card-box-dashbrd">
            <div className="FullViews card">
              <Link
                to={`/offerlist?u=${UserId}&s=delivered&d=${filterDay}&sd=${startDate}&ed=${endDate}`}
                className="d-flex"
              >
                <h6 className="m-0 ">Delivered Offers count & Amount</h6>
                <h3 className="m-0 full-amount">
                  {cardResponse?.delivered_offers?.cnt ? cardResponse?.delivered_offers?.cnt : 0} - $
                  {cardResponse?.delivered_offers?.amount ? cardResponse?.delivered_offers?.amount : 0}
                </h3>
              </Link>
            </div>
          </div>
          <div className="col-xl-2 col-md-6 col-6 pb-4 card-box-dashbrd">
            <div className="FullViews card">
              <Link
                to={`/offerlist?u=${UserId}&s=delivered-with-defects&d=${filterDay}&sd=${startDate}&ed=${endDate}`}
                className="d-flex"
              >
                <h6 className="m-0 ">Delivered with defects Count & Amount</h6>
                <h3 className="m-0 full-amount">
                  {cardResponse?.delivered_with_defects_offers?.cnt
                    ? cardResponse?.delivered_with_defects_offers?.cnt
                    : 0}{" "}
                  - $
                  {cardResponse?.delivered_with_defects_offers?.amount
                    ? cardResponse?.delivered_with_defects_offers?.amount
                    : 0}
                </h3>
              </Link>
            </div>
          </div>
          <div className="col-xl-2 col-md-6 col-6 pb-4 card-box-dashbrd">
            <div className="FullViews card">
              <Link
                to={`/offerlist?u=${UserId}&s=cancelled&d=${filterDay}&sd=${startDate}&ed=${endDate}`}
                className="d-flex"
              >
                <h6 className="m-0 ">Cancelled Offers Count & Amount</h6>
                <h3 className="m-0 full-amount">
                  {cardResponse?.cancelled_offers?.cnt ? cardResponse?.cancelled_offers?.cnt : 0}- $
                  {cardResponse?.cancelled_offers?.amount ? cardResponse?.cancelled_offers?.amount : 0}
                </h3>
              </Link>
            </div>
          </div>
          <div className="col-xl-2 col-md-6 col-6 pb-4 card-box-dashbrd">
            <div className="FullViews card">
              <Link
                to={`/offerlist?u=${UserId}&s=not-approved&d=${filterDay}&sd=${startDate}&ed=${endDate}`}
                className="d-flex"
              >
                <h6 className="m-0 ">Not Approved Offers Count & Amount</h6>
                <h3 className="m-0 full-amount">
                  {cardResponse?.not_approved_offers?.cnt ? cardResponse?.not_approved_offers?.cnt : 0}- $
                  {cardResponse?.not_approved_offers?.amount ? cardResponse?.not_approved_offers?.amount : 0}
                </h3>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellerPage;
