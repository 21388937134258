import React, { useState, useEffect, useContext } from "react";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import LinkName from "../LinkName/LinkName";
import UserService from "../../services/UserService";
import Pagination from "react-bootstrap/Pagination";
import GlobalContext from "../../context/GlobalContext";
moment.tz.setDefault(process.env.REACT_APP_MOMENT_TIME_ZONE);
export default function Users() {
  const sitectx = useContext(GlobalContext);
  const userServe = new UserService();
  const [userList, setUserList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [contentLoaded, setContentLoaded] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const [search, setSearch] = useState({
    start: 0,
    perPage: 10,
    searchTxt: "",
    searchField: "",
  });

  function searchProfileList(e) {
    search.searchTxt = e.target.value;
    search.start = 0;
    getUserListApi();
  }

  async function getUserListApi() {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
        searchId:
          sitectx.searchDtl && sitectx.searchDtl.type === "user" ? sitectx.searchDtl && sitectx.searchDtl.value : "",
      },
    };
    if (searchParams.get("s")) {
      activity.filter.is_active = searchParams.get("s") == "active";
    }
    if (searchParams.get("d")) {
      if (searchParams.get("d") == "this_year") {
        activity.filter.days = moment().dayOfYear();
      } else {
        activity.filter.days = parseInt(searchParams.get("d"));
      }
    }
    if (searchParams.get("sd")) {
      activity.filter.startDate = searchParams.get("sd");
    }
    if (searchParams.get("ed")) {
      activity.filter.endDate = searchParams.get("ed");
    }
    try {
      let response = await userServe.userListApi(activity);
      if (response) {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].createdAt = moment(response.data[i].createdAt).format("MM-DD-YYYY ");
        }
        setUserList(response.data);
        setTotalCount(response.total);
        setContentLoaded(true);
      } else {
        setUserList([]);
        setTotalCount(0);
      }
    } catch (err) {
      throw err;
    }
  }

  const handlePaging = (e) => {
    if (e.target.text) {
      let tempSearch = { ...search };
      tempSearch.start = parseInt(e.target.text) * search.perPage - search.perPage;
      setSearch(tempSearch);
    }
  };

  let active = Math.ceil((search.start + 1) / search.perPage);
  let pages = Math.ceil(totalCount / search.perPage);

  let items = [];
  for (let number = 1; number <= pages; number++) {
    items.push(
      <Pagination.Item key={number} onClick={handlePaging} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  useEffect(() => {
    getUserListApi();
  }, [searchParams, search, sitectx.searchDtl && sitectx.searchDtl.value]);

  const deleteUserRecord = async (userId) => {
    try {
      if (window.confirm("Are you sure you want to delete this record?")) {
        let response = await userServe.deleteUserRecordApi(userId);
        if (response.message) {
          getUserListApi();
          toast.success(response.message);
          setTotalCount(totalCount - 1);
          let userListTem = [...userList];

          const user = userListTem.filter((v) => {
            return v._id !== userId;
          });
          setUserList(user);
        } else {
          toast.error("Record is not deleted ");
        }
      }
    } catch (err) {
      throw err;
    }
  };
  const addUser = () => {
    navigate("/adduser");
  };
  const userEdit = async (id) => {
    navigate("/edituser/" + id);
  };
  const activeToggleHandler = async (value) => {
    try {
      let isActive = value.is_active;
      let obj = {
        is_active: !isActive,
        id: value.id,
      };
      let response = await userServe.edit(obj);
      if (response.message) {
        getUserListApi();
      }
    } catch (err) {
      toast.error("something went wrong!");
      throw err;
    }
  };
  const activeButton = (data) => {
    return (
      <div className="updates_icon d-flex align-items-center">
        <label className="press me-2">
          <input
            type="checkbox"
            className="cbx hidden"
            checked={data?.is_active}
            onChange={() => activeToggleHandler(data)}
          />
          <span className="lbl"></span>
        </label>
        {data?.is_active ? <label>Active</label> : <label>Inactive</label>}
      </div>
    );
  };
  const getList = async () => {
    getUserListApi();
  };

  const downloadJson = async () => {
    const fileType = "text/csv";
    const fileName = "users.csv";
    let headers = [
      "Id,First Name,Last Name,Email,Phone,Address1,Address2,Address3,City,State,Role,Agreement,isActive,Country,Company Name,Created At,Updated At",
    ];
    let usersCsv = userList.reduce((acc, user) => {
      const {
        id,
        first_name,
        last_name,
        email,
        phone,
        address_1,
        address_2,
        address_3,
        city,
        state,
        role,
        agreement,
        is_active,
        country,
        company_name,
        createdAt,
        updatedAt,
      } = user;
      acc.push(
        [
          id,
          first_name,
          last_name,
          email,
          phone,
          address_1,
          address_2,
          address_3,
          city,
          state,
          role,
          agreement,
          is_active,
          country,
          company_name,
          createdAt,
          updatedAt,
        ].join(",")
      );
      return acc;
    }, []);
    const data = [...headers, ...usersCsv].join("\n");
    console.log("data to download is ", data);
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  return (
    <div className="ljSectionData w-100 clearfix ljpUserTables pb-4 p-relative" id="ljpUserTables">
      {/* {openModal && (
        <Modal
          closeModal={setOpenModal}
          data={selectedUser}
          items={userList}
          modalData={setUserList}
          getList={getList}
        />
      )} */}
      <LinkName heading="User Database" image="/assets/images/icons/user.png" link="/user_table" />
      {!contentLoaded ? (
        <div className="loadingImg">
          <img src="/assets/images/loading.gif" className="text-center" />
        </div>
      ) : (
        <div className="user_part">
          <div className="users_bottom_part">
            <div
              className="d-flex flex-wrap user_bottom_header align-items-center page-header"
              style={{ justifyContent: "space-between" }}
            >
              <div className=" text-heading">
                <h4 className="mb-0">All Users</h4>
              </div>
              <div>
                <button type="primary" className="add-user-btn" onClick={addUser}>
                  Add User
                </button>
                <button type="primary" className="btn btn-secondary remove-items-btn" onClick={downloadJson}>
                  DownLoad CSV
                </button>
              </div>
            </div>
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 10,
              }}
            >
              <div className="col-md-6 col-lg-6 col-sm-12 mt-0 ml-1"></div>
              <div className="col-md-4 col-lg-4 col-sm-12">
                <div className="input-group update_search_bar w-100">
                  <button className="btn" type="button"></button>
                  <input
                    type="text"
                    className="form-control border-0"
                    placeholder="Search Here"
                    onChange={(e) => {
                      searchProfileList(e);
                    }}
                  />
                  <div className="input-group-append">
                    <button className="btn search_btn" type="submit">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="users_table mt-3">
              <div className="current_courses_table table-responsive">
                <table className="main_table w-100">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th className="course_id">Email</th>
                      <th>Phone</th>
                      <th> Address 1</th>
                      <th>Created At</th>
                      <th>Role</th>
                      <th>Status</th>
                      <th className="action_icons">ACTIONS</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList.map((key, i) => {
                      return (
                        <tr key={i}>
                          <td>{`${key.first_name} ${key.last_name}`}</td>
                          <td>{key.email}</td>
                          <td>{key.phone ? key.phone : <span style={{ color: "red" }}> Not Found </span>}</td>
                          <td>{key.address_1 ? key.address_1 : <span style={{ color: "red" }}> Not Found </span>}</td>
                          <td>{key.createdAt}</td>
                          <td>
                            {key.role == 2 ? (
                              <span style={{ color: "red" }}> Seller </span>
                            ) : key.role == 1 ? (
                              <span style={{ color: "blue" }}> Admin </span>
                            ) : (
                              <span style={{ color: "green" }}> -- </span>
                            )}
                          </td>
                          <td>{activeButton(key)}</td>
                          <td>
                            <div className="updates_icon p-0">
                              <Link
                                to="#"
                                onClick={() => {
                                  deleteUserRecord(key.id);
                                }}
                                className="delIconCustom fa-sharp me-1 fa-solid fa-trash"
                              ></Link>
                              <a
                                className="editIconCustom fa-sharp me-1 fa-solid fa-pen-to-square"
                                onClick={() => userEdit(key.id)}
                              ></a>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            {userList.length == 0 && (
              <div className="row mb-3">
                <div className="col-4"></div>
                <div className="col-4">
                  <p>No records found</p>
                </div>
                <div className="col-4"></div>
              </div>
            )}
            <div className="users_table_footer">
              <Pagination size="sm">{items}</Pagination>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
