import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import LoginPage from "../pages/Login/LoginPage";
import HomePage from "../pages/Home/HomePage";
import DefaultLayout from "../containers/DefaultLayout";
import Users from "../pages/Users/Users";
import ResetPassword from "../pages/Login/ForgotPassword";
import UpdatePassword from "../pages/Login/ResetPassword";
import { GlobalProvider } from "../context/GlobalContext";
import SellerDetails from "../pages/Users/SellerDetails";
import AddUser from "../pages/Users/AddUser";
import AddOffers from "../pages/Offers/AddOffers";
import OfferList from "../pages/Offers/OfferList";
import PreviewOffers from "../pages/Offers/PreviewOffers";
import ModifyTestimonial from "../pages/testimonial/ModifyTestimonial";
import TestimonialList from "../pages/testimonial/TestimonialList";
import PaymentDetails from "../pages/Payment/PaymentDetails";
import Message from "../pages/message/Message";
import Inventory from "../pages/Inventory/Inventory";
import InventoryHistory from "../pages/Inventory/InventoryHistory";
import Banner from "../pages/Banner/Banner";
import ShippingAddress from "../pages/Shipping/ShippingAddress";
import ScrollToTop from "../containers/ScrollToTop";
import BannerList from "../pages/Banner/BannerList";
function AllRoutes() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    window.user ? true : false
  );

  useEffect(() => {
    getUser();
  }, [isAuthenticated]);

  const getUser = () => {
    if (window.user) {
      setIsAuthenticated(true);
    }
  };

  return isAuthenticated && window.user ? (
    <GlobalProvider>
      <DefaultLayout handleAuthState={setIsAuthenticated}>
        <ScrollToTop/>
        <Routes>
          <Route exact path="/home" element={<HomePage />} />
          <Route exact path="/user" element={<Users />} />
          <Route exact path="/seller/:seller_id" element={<SellerDetails />} />
          <Route exact path="/adduser" element={<AddUser />} />
          <Route exact path="/edituser/:id" element={<AddUser />} />

          <Route exact path="/createoffer" element={<AddOffers />} />
          <Route exact path="/createoffer/:id" element={<AddOffers />} />
          <Route exact path="/offerlist" element={<OfferList />} />
           <Route exact path="/offer/preview/:id" element={<PreviewOffers />} />
          <Route exact path="/testimonial/:id" element={<ModifyTestimonial />} />
          <Route exact path="/testimonial" element = {<ModifyTestimonial />} />
          <Route exact path="/testimonial/list" element={<TestimonialList />} />
          <Route exact path="/paymentdetails/:id/:userId" element={<PaymentDetails />} />
          <Route exact path="/message/:id" element={ <Message />} />
          <Route exact path="/inventory/list" element = { <Inventory/> } />
          <Route exact path="/inventory/history/:upc" element = { <InventoryHistory />} />
          <Route path="*" element={<Navigate to="/home" />} />
          <Route exact path="/banner" element={ <Banner />}/>
          <Route exact path="/banner/:id" element={ <Banner />}/>
          <Route exact path="/shipping" element={ <ShippingAddress/> } />
          <Route exact path="/bannerList" element={ <BannerList/> } />
        </Routes>
      </DefaultLayout>
    </GlobalProvider>
  ) : (
    <Routes>
      <Route
        exact
        path="/login"
        element={<LoginPage handleAuthState={setIsAuthenticated} />}
      />
      <Route exact path="/forgot_password" element={<ResetPassword />} />
      <Route exact path="/reset/:token" element={<UpdatePassword />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default AllRoutes;
