import util from "../util/util";

export default class PieChartService {
    getData (){
        return util.sendApiRequest("/report/piechart","GET",true,{})
        .then(
            (response)=>{
                return response;
            },
            (error)=>{
                throw error;
            }
            
        )
    }
}

