import util from "../util/util";

export default class OfferService {
  add(payload) {
    return util.sendApiRequestWithFiles("/offer", "POST", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  addInventoryHistory(payload) {
    return util.sendApiRequestWithFiles("/offer/inventoryHistory", "POST", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  async Messageslist(activity) {
    return util.sendApiRequest("/offer/message/list", "POST", true, activity).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  clone(dataId) {
    return util.sendApiRequest(`/offer/clone/${dataId}`, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  deleteOffer(dataId) {
    return util.sendApiRequest("/offer/" + dataId, "DELETE", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  getOfferInfo(dataId) {
    return util
      .sendApiRequest("/offer/" + dataId, "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  edit(payload) {
    return util.sendApiRequestWithFiles("/offer", "PUT", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  listApi(data) {
    const activity = Object.keys(data).reduce((object, key) => {
      if (data[key] !== "") {
        object[key] = data[key];
      }
      return object;
    }, {});
    return util.sendApiRequest("/offer/list", "POST", true, activity).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  GetPdf(dataId) {
    return util
      .sendApiRequest("/offer/downloadPdf/" + dataId + "/" + "admin", "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  offerHistory(activity) {
    return util.sendApiRequest("/offer/remarks/list", "POST", true, activity).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  getInventoryList(activity) {
    return util.sendApiRequest("/offer/inventory/list", "POST", true, activity).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  updateProductName(payload) {
    return util.sendApiRequest("/offer/inventory", "PUT", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  updateQuantity(payload) {
    return util.sendApiRequest("/offer/inventory/history", "POST", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  getInventory(upc) {
    return util.sendApiRequest("/offer/inventory/" + upc, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  getInventoryHistory(payload) {
    return util.sendApiRequest("/offer/inventory/history/upc", "POST", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  getShipping() {
    return util.sendApiRequest("/offer/shipping/details", "GET", true, {}).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  postShipping(payload) {
    return util.sendApiRequest("/offer/shipping/details", "POST", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  setTrackingInfo(payload) {
    return util.sendApiRequest("/offer/trackinginfo", "PUT", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
}
