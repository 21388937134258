import moment from "moment";
import axios from "axios";

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export default {
  async sendApiRequest(url, method, setauth, body) {
    const requestOptions = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    };
    if (method === "DELETE") {
      delete requestOptions.body;
    }
    if (method === "GET") {
      delete requestOptions.body;
    }
    // console.log(window.user);
    if (setauth === true) {
      //   let tokenId =JSON.parse( window.localStorage.getItem("user")).token
      //  let token = tokenId ? tokenId : "no-token";
      let token = window.user.token ? window.user.token : "no-token";
      requestOptions.headers["Authorization"] = "Bearer " + token;
    }
    try {
      const response = await fetch(process.env.REACT_APP_API_BASEURL + url, requestOptions);
      let body = await response.text();
      if (response.status != 200) {
        throw body;
      }
      const data = body.includes("{") ? JSON.parse(body) : body;
      return data;
    } catch (e) {
      throw e;
    }
  },
  async sendApiRequestWithFiles(url, method, setauth, body) {
    const config = {
      url: process.env.REACT_APP_API_BASEURL + url,
      method: method,
      headers: {
        content: "multipart/form-data",
      },
      data: body,
    };
    if (method === "DELETE") {
      delete config.body;
    }
    if (method === "GET") {
      delete config.body;
    }
    if (setauth === true) {
      //   let tokenId = JSON.parse( window.localStorage.getItem("user")).token;
      // let token = tokenId ? tokenId: "no-token";
      // console.log("token is",token)
      let token = window.user.token ? window.user.token : "no-token";
      config.headers["Authorization"] = "Bearer " + token;
    }
    try {
      const response = await axios(config);
      if (response.data.err) {
        throw new Error(response.data.err);
      } else {
        return response.data;
      }
    } catch (err) {
      throw err;
    }
  },
  getCurrentTime(endDate) {
    const startDate = moment(new Date());

    if (startDate.diff(moment(endDate), "months") > 0) {
      return startDate.diff(moment(endDate), "months") + " months ago";
    }
    if (startDate.diff(moment(endDate), "weeks") > 0) {
      return startDate.diff(moment(endDate), "weeks") + " weeks ago";
    }
    if (startDate.diff(moment(endDate), "days") > 0) {
      return startDate.diff(moment(endDate), "days") + " days ago";
    }
    if (startDate.diff(moment(endDate), "hours") > 0) {
      return startDate.diff(moment(endDate), "hours") + " hours ago";
    }
    if (startDate.diff(moment(endDate), "minutes") > 0) {
      return startDate.diff(moment(endDate), "minutes") + " minutes ago";
    }
    return "just now";
  },
  formatDate(date) {
    return [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join("-");
  },
  formatDateTime(date) {
    return (
      [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join("-") +
      " " +
      [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(":")
    );
  },
  formatDateUTC(date) {
    let offset = date.getTimezoneOffset();
    let d = new Date(date.getTime() + offset * 60 * 1000);
    return [d.getFullYear(), padTo2Digits(d.getMonth() + 1), padTo2Digits(d.getDate())].join("-");
  },
  formatDateTimeUTC(date) {
    let offset = date.getTimezoneOffset();
    let d = new Date(date.getTime() + offset * 60 * 1000);
    return (
      [d.getFullYear(), padTo2Digits(d.getMonth() + 1), padTo2Digits(d.getDate())].join("-") +
      " " +
      [padTo2Digits(d.getHours()), padTo2Digits(d.getMinutes()), padTo2Digits(d.getSeconds())].join(":")
    );
  },
  formatDateAsREACT_APP_MOMENT_TIME_ZONE(date) {
    const dt = new Date();
    const utcDate = new Date(dt.toLocaleString("en-US", { timeZone: "UTC" }));
    const tzDate = new Date(dt.toLocaleString("en-US", { timeZone: process.env.REACT_APP_MOMENT_TIME_ZONE }));
    let offset = (utcDate.getTime() - tzDate.getTime()) / 6e4;
    let d = new Date(date.getTime() + offset * 60 * 1000);
    return [d.getFullYear(), padTo2Digits(d.getMonth() + 1), padTo2Digits(d.getDate())].join("-");
  },
};
