import { useFormik, Formik } from "formik";
import { useParams, useNavigate, Link } from "react-router-dom";
import * as Yup from "yup";
import { useEffect, useMemo, useState } from "react";
import PaymentService from "../../services/PaymentService";
import OfferService from "../../services/OfferService";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
const ValidateSchema = Yup.object({
  payment_method_id: Yup.string().required("Payment Method is a required field"),
  details: Yup.string().required("Payment Details is a required field"),
  date: Yup.string().required("Date is required"),
  transaction_id: Yup.string().required("Transaction is required"),
  remarks: Yup.string().required("Remarks is required"),
  amount: Yup.string().required("Amount is required"),
});
function PaymentDetails() {
  const paymentServ = new PaymentService();
  const service = new OfferService();
  const navigate = useNavigate();
  const { id, userId } = useParams();
  const [offerDetails, setOfferDetails] = useState("");
  const [totalPendingAmount, setTotalPendingAmount] = useState("");
  const [existingPMDetails, setExistingPMDetails] = useState({
    found: null,
    details: [],
  });
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState({
    payment_method_id: "",
    details: "",
    date: "",
    transaction_id: "",
    remarks: "",
    amount: "",
  });

  // useEffect(() => {
  //   if (id) {
  //     fetchOfferDetails(id);
  //   }
  // }, []);

  // const fetchOfferDetails = async (id) => {
  //   try {
  //     const response = await service.getOfferInfo(id);
  //     if (response) {
  //       setOfferDetails((prev) => response.offer);
  //     }
  //   } catch (err) {}
  // };

  const onSubmit = async () => {
    if (
      (parseInt(formik.values.amount) > parseInt(totalPendingAmount) && confirm("Total Amount is Greater")) ||
      parseInt(formik.values.amount) <= parseInt(totalPendingAmount)
    ) {
      const paymentData = { ...formik.values };
      paymentData.offer_id = id;
      paymentData.user_id = userId;

      if (existingPMDetails.details.length > 0) {
        let paymentMethod = existingPMDetails.details.filter((value) => {
          return value.id == paymentData.payment_method_id;
        });
        paymentData.platform = paymentMethod[0].platform;
      }
      // paymentData.platform = paymentMethod;
      const result = await paymentServ.add(paymentData);
      if (result.result) {
        toast.success(result.message);
        getUserPaymentDetails();
      } else {
        toast.error("Payment Detail not Added");
      }
    } else {
      return;
    }
  };

  const deletePaymentDetail = async (pId, offerId) => {
    try {
      if (window.confirm(`Are you sure you want to delete this payment for Offer #${offerId} ?`)) {
        let response = await paymentServ.deletePaymentDetail(pId);
        if (response.message) {
          getUserPaymentDetails();
          toast.success(response.message);
        } else {
          toast.error("Payment Detail not Deleted");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    initialValues: paymentDetails,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  const getUserPaymentDetails = async () => {
    let result = await paymentServ.getPaymentinfo(userId);
    let historydata = await paymentServ.getPaymentHistory();
    let pendingAmount = await paymentServ.getTotalAmount(id);
    if (historydata) {
      setPaymentHistory((prev) => historydata);
      if (pendingAmount.totalAmount) {
        setTotalPendingAmount(pendingAmount.totalAmount);
      }
    }
    if (result.data.length > 0) {
      const existingDetails = {
        found: null,
        details: result.data,
      };
      setExistingPMDetails((prev) => existingDetails);
      const response = await service.getOfferInfo(id);
      if (response.offer) {
        setOfferDetails((prev) => response.offer);
        const firstMethodId = result.data[0].id;
        const newPaymentDetails = {
          payment_method_id: firstMethodId,
          details: "",
          date: moment(new Date()).format("YYYY-MM-DD"),
          transaction_id: "",
          remarks: "",
          amount: pendingAmount.totalAmount ? pendingAmount.totalAmount : "",
        };
        setPaymentDetails((prev) => newPaymentDetails);
      }
    } else {
      const existingDetails = {
        found: "Payment Details not yet entered by the users",
        details: result.data,
      };
      setExistingPMDetails((prev) => existingDetails);
    }
  };
  useEffect(() => {
    getUserPaymentDetails();
  }, []);

  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };

  return (
    <div>
      {/* <div
      className="ljSectionData w-100 clearfix ljpUserTables pb-4 p-relative"
      id="ljpUserTables"
    > */}
      <div className="m-4">
        <div className="user_part">
          <div className="users_bottom_part">
            <div className="text-heading my-2 mx-4 row">
              <h4 className=" text-center m-auto col-11">Payment Details</h4>
              <button
                className="btn btn-secondary remove-items-btn col-1 payment-btn-height mt-0"
                onClick={() => navigate("/offerlist")}
              >
                Back
              </button>
            </div>
            <h5 className="text-danger ms-4">{existingPMDetails.found}</h5>
            <Formik>
              <form initialValues={paymentDetails} onSubmit={formik.handleSubmit}>
                <div className="m-3">
                  <div className="row  mb-4">
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">Payment Method*</label>
                        <select
                          type="text"
                          className="form-control"
                          value={formik.values.payment_method_id}
                          name="payment_method_id"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          {Array.isArray(existingPMDetails.details) && existingPMDetails.details.length > 0 ? (
                            <>
                              {existingPMDetails.details.map((detail) => {
                                return (
                                  <option key={detail.platform} value={detail.id}>
                                    {detail.platform}
                                  </option>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              <option value="">Select payout method</option>
                              <option value="zelle">Zelle</option>
                              <option value="paypal">Paypal</option>
                            </>
                          )}
                        </select>

                        {formik.touched.platform && formik.errors.platform
                          ? dispErrorMsg("Payment Method is required")
                          : null}
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">Amount*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Amount"
                          value={formik.values.amount}
                          name="amount"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.amount && formik.errors.amount ? dispErrorMsg("Amount is required") : null}
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-8 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">Payment details*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your payment id or phone number"
                          value={formik.values.details}
                          name="details"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.details && formik.errors.details
                          ? dispErrorMsg("Payment Details is required")
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Date*</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="mm-dd-yyyy"
                          value={formik.values.date}
                          name="date"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.date && formik.errors.date ? dispErrorMsg("Date is required") : null}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Transaction Id*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Transaction Id"
                          value={formik.values.transaction_id}
                          name="transaction_id"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.transaction_id && formik.errors.transaction_id
                          ? dispErrorMsg("Transaction Id  is required")
                          : null}
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-4">
                      <div className="form-group">
                        <label className="form-label">Remarks*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Remarks"
                          value={formik.values.remarks}
                          name="remarks"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.remarks && formik.errors.remarks ? dispErrorMsg("Remarks is required") : null}
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-primary m-3" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </Formik>
          </div>
        </div>
      </div>
      {/* </div> */}
      <div className="user_part m-4">
        <div className="user_bottom_part">
          <div className="text-heading ">
            <h4 className="m-4 text-center">Payment history</h4>
          </div>
        </div>
        <div className="m-2">
          {/* <div className="table-responsive statement-card"> */}
          {/* <table className="table card-table table-vcenter text-nowrap border-bottom"> */}
          <div className="border rounded p-3 m-2">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th>Offer ID</th>
                  <th>Platform</th>
                  <th>Payment Id (Email/Phone number)</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Transaction Id</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="tableBody-custPD">
                {Array.isArray(paymentHistory) && paymentHistory.length > 0 ? (
                  <>
                    {paymentHistory.map((key, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-primary">
                            {key.offer_id ? <Link to={`/offer/preview/${key.offer_id}`}>{key.offer_id}</Link> : "NF"}
                          </td>
                          <td>{key.platform ? key.platform : "NF"}</td>
                          <td className="fs-16">{key.details ? key.details : "NF"}</td>
                          <td>{key.amount ? key.amount : "NF"}</td>

                          <td>{key.date ? key.date : "NF"}</td>

                          <td>{key.transaction_id ? key.transaction_id : "NF"}</td>
                          <td className="actionBtn">
                            <a
                              className="fa fa-trash action-icons delete-btn text-center "
                              onClick={() => deletePaymentDetail(key.id, key.offer_id)}
                            ></a>
                            {/* <a
                              className="fa fa-eye action-icons eye-btn "
                              // onClick={() => previewOffer(key)}
                            ></a> */}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
export default PaymentDetails;
