import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UserService from "../../services/UserService";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";

const serv = new UserService();
const ValidateSchema = Yup.object({
  first_name: Yup.string().required("First Name is a required field"),
  last_name: Yup.string().required("Last name is a required field"),
  phone: Yup.string().required("Phone number is required"),
  address_1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
});

export default function SellerDetails() {
  const { seller_id } = useParams();
  const userServe = new UserService();
  const [sellerInfo, setSellerInfo] = useState([]);
  const [userDetails, setUserDetails] = useState([]);

  useEffect(() => {
    console.log("The Seller ID is:", seller_id);
    fetchSellerInfo(seller_id);
  }, [seller_id]);

  const fetchSellerInfo = async (id) => {
    if (id) {
      try {
        let response = await userServe.getUserInfo(id);
        if (response) {
          console.log("The User is:", response);
          setUserDetails(response);
        } else {
        }
      } catch (err) {
        throw err;
      }
    }
  };

  const onSubmit = async (details) => {
    let obj = details;
    try {
      const response = await serv.edit(obj);
      // return;
      // setCheckUserInfo(true);
      toast(response.message);
    } catch (err) {
      toast(err);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: userDetails,
    onSubmit,
    validationSchema: ValidateSchema,
  });
  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };

  return (
    <div>
      <section class="sptb">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body user-pattern bg-background2">
                  <div className="wideget-user">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="wideget-user-desc text-center">
                          <div className="wideget-user-img">
                            <img
                              className="brround"
                              src="../assets/images/users/male/2.jpg"
                              alt="img"
                            />
                          </div>
                          <div className="user-wrap wideget-user-info">
                            <a
                              href="javascript:void(0);"
                              className="text-white"
                            >
                              <h4 className="font-weight-semibold">
                                Jacob Smith
                              </h4>
                            </a>
                            <span className="text-white-50">
                              Member Since May 2012
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="wideget-user-tab">
                    <div className="tab-menu-heading">
                      <div className="tabs-menu1">
                        <ul className="nav">
                          <li className>
                            <a
                              href="#tab-5"
                              className="active"
                              data-bs-toggle="tab"
                            >
                              Profile
                            </a>
                          </li>
                          <li>
                            <a href="#tab-7" data-bs-toggle="tab" className>
                              My Item List
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-0">
                <div className="tab-content">
                  <div className="tab-pane active" id="tab-5">
                    <div className="card mb-0">
                      <div className="card-body">
                        <form onSubmit={formik.handleSubmit}>
                          <div className="card mb-0">
                            <div className="card-header">
                              <h3 className="card-title">Edit Profile</h3>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-sm-6 col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      First Name
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="First Name"
                                      onChange={formik.handleChange}
                                      value={formik.values.first_name}
                                      name="first_name"
                                      className={
                                        "form-control" +
                                        (formik.touched.first_name &&
                                        formik.errors.first_name
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    {formik.touched.first_name &&
                                    formik.errors.first_name
                                      ? dispErrorMsg("First Name is required")
                                      : null}
                                  </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Last name
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Last name"
                                      onChange={formik.handleChange}
                                      value={formik.values.last_name}
                                      name="last_name"
                                      className={
                                        "form-control" +
                                        (formik.touched.last_name &&
                                        formik.errors.last_name
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    {formik.touched.last_name &&
                                    formik.errors.last_name
                                      ? dispErrorMsg("Last name is required")
                                      : null}
                                  </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Email address
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Email"
                                      onChange={formik.handleChange}
                                      value={formik.values.email}
                                      disabled
                                      name="email"
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Phone Number
                                    </label>
                                    <input
                                      type="number"
                                      placeholder="Number"
                                      onChange={formik.handleChange}
                                      value={formik.values.phone}
                                      name="phone"
                                      className={
                                        "form-control" +
                                        (formik.touched.phone &&
                                        formik.errors.phone
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    {formik.touched.phone && formik.errors.phone
                                      ? dispErrorMsg("Phone number is required")
                                      : null}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Address
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Home address"
                                      onChange={formik.handleChange}
                                      value={formik.values.address_1}
                                      name="address_1"
                                      className={
                                        "form-control" +
                                        (formik.touched.address_1 &&
                                        formik.errors.address_1
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    {formik.touched.address_1 &&
                                    formik.errors.address_1
                                      ? dispErrorMsg("Address is required")
                                      : null}
                                  </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">City</label>
                                    <input
                                      type="text"
                                      placeholder="City"
                                      onChange={formik.handleChange}
                                      value={formik.values.city}
                                      name="city"
                                      className={
                                        "form-control" +
                                        (formik.touched.city &&
                                        formik.errors.city
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    {formik.touched.city && formik.errors.city
                                      ? dispErrorMsg("city is required")
                                      : null}
                                  </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">State </label>
                                    <input
                                      type="text"
                                      placeholder="State"
                                      onChange={formik.handleChange}
                                      value={formik.values.state}
                                      name="state"
                                      className={
                                        "form-control" +
                                        (formik.touched.state &&
                                        formik.errors.state
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    {formik.touched.state && formik.errors.state
                                      ? dispErrorMsg("State is required")
                                      : null}
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Country
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Country"
                                      onChange={formik.handleChange}
                                      value={formik.values.country}
                                      name="country"
                                      className={
                                        "form-control" +
                                        (formik.touched.country &&
                                        formik.errors.country
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    {formik.touched.country &&
                                    formik.errors.country
                                      ? dispErrorMsg("Country is required")
                                      : null}
                                  </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Company Name (Optional)
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Company"
                                      onChange={formik.handleChange}
                                      value={formik.values.company_name}
                                      name="company_name"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-footer">
                              <button
                                type="submit"
                                className="btn ripple  btn-secondary"
                              >
                                Update Profile
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane userprof-tab" id="tab-7">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
