import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TestimonalServices from "../../services/TestimonialService";
import LinkName from "../LinkName/LinkName";
import Pagination from "react-bootstrap/Pagination";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment-timezone";
moment.tz.setDefault(process.env.REACT_APP_MOMENT_TIME_ZONE);
export default function TestimonialList() {
  const TestimonialServ = new TestimonalServices();
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [testimonialList, setTestimonialList] = useState([]);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);
  const navigate = useNavigate();

  async function getTestimonialListApi() {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {},
      sortBy: search.sortBy,
    };
    try {
      let response = await TestimonialServ.listApi(activity);
      if (response) {
        for (let i = 0; i < response.data.rows.length; i++) {
          response.data.rows[i].createdAt = moment(response.data.rows[i].createdAt).format("YYYY-MM-DD");
        }
        setTestimonialList(response.data.rows);
        setTotalCount(response.total);
        setContentLoaded(true);
      } else {
        setTestimonialList([]);
        setTotalCount(0);
      }
    } catch (err) {
      throw err;
    }
  }

  const [search, setSearch] = useState({
    start: 0,
    perPage: 10,
    searchTxt: "",
    searchField: "",
  });

  const handlePaging = (e) => {
    if (e.target.text) {
      let tempSearch = { ...search };
      tempSearch.start = parseInt(e.target.text) * search.perPage - search.perPage;
      setSearch(tempSearch);
    }
  };

  let active = Math.ceil((search.start + 1) / search.perPage);
  let pages = Math.ceil(totalCount / search.perPage);

  let items = [];
  for (let number = 1; number <= pages; number++) {
    items.push(
      <Pagination.Item key={number} onClick={handlePaging} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  useEffect(() => {
    getTestimonialListApi();
  }, [deleteRequest]);

  const deleteTestimonial = async (Id) => {
    try {
      if (window.confirm("Are you sure you want to delete this Offer?")) {
        let id = parseInt(Id);
        let response = await TestimonialServ.deleteTestimonial(id);
        setDeleteRequest((prev) => !prev);
      }
    } catch (err) {
      throw err;
    }
  };

  const addNewTestimonial = () => {
    navigate("/testimonial");
  };
  const editTestimonial = async (Id) => {
    navigate(`/testimonial/${Id}`);
  };

  return (
    <div className="ljSectionData w-100 clearfix ljpUserTables pb-4 p-relative" id="ljpUserTables">
      {/* {openModal && (
        <Modal
          closeModal={setOpenModal}
          data={selectedUser}
          items={testimonialList}
          modalData={setTestimonialList}
          getList={getTestimonialListApi}
        />
      )} */}
      <LinkName heading="User Database" image="/assets/images/icons/user.png" link="/user_table" />
      {!contentLoaded ? (
        <div className="loadingImg">
          <img src="/assets/images/loading.gif" className="text-center" />
        </div>
      ) : (
        <div className="user_part">
          <div className="users_bottom_part">
            <div
              className="d-flex flex-wrap user_bottom_header align-items-center margintxt"
              style={{ justifyContent: "space-between" }}
            >
              <div className=" text-heading">
                <h4 className="mb-0">All Testimonial</h4>
              </div>
              <div>
                <button type="primary" className="add-user-btn" onClick={() => addNewTestimonial()}>
                  Add Testimonial
                </button>
              </div>
            </div>
            <div className="para"></div>
            <div className="users_table mt-3">
              <div className="current_courses_table table-responsive">
                <table className="main_table w-100">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Message</th>
                      <th>Designation </th>
                      <th>Image </th>
                      <th className="action_icons">ACTIONS</th>
                    </tr>
                  </thead>

                  <tbody>
                    {testimonialList?.map((key, i) => {
                      return (
                        <tr key={i}>
                          <td> {key?.name} </td>
                          <td>{key?.message}</td>
                          <td>{key?.designation}</td>
                          <td>{key?.image}</td>
                          <td style={{ position: "relative" }}>
                            <div className="updates_icon p-0">
                              <span
                                onClick={() => {
                                  deleteTestimonial(key.id);
                                }}
                                className="delIconCustom fa-sharp me-1 fa-solid fa-trash"
                              ></span>
                              <span
                                onClick={() => editTestimonial(key.id)}
                                className="editIconCustom fa-sharp me-1 fa-solid fa-pen-to-square"
                              ></span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="users_table_footer">
              <Pagination size="sm">{items}</Pagination>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
